<template lang='pug'>
  modal(name='railroad-temporary-active-edit' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-remover-modal
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{$t('railroad_temporary_active_editor.title')}}

      .content
        form.ui.form
          .fields
            .field.sixteen
              div(class="field select optional")
                label(class="select optional" for="railroad_disable_active_in_minutes")
                  | {{$t('railroad_temporary_active_editor.label')}}
                input(type='number' v-bind:placeholder='$t("railroad_temporary_active_editor.minutes")' min=0 id='railroad_disable_active_in_minutes' v-model='railroad_disable_active_in_minutes')

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('close') }}
        .ui.blue.button(v-on:click='editRailroadTemporaryActiveSubmit' v-if="railroad_disable_active_in_minutes")
          | {{ $t('save') }}
</template>
<script>
import { mapState } from 'vuex'
import 'vue-slider-component/theme/antd.css'
import { error as errNotify, success as succNotify } from '../../../utils/notify'
import DatePicker from 'vue2-datepicker'
import moment from 'moment'

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      railroad_disable_active_in_minutes: null,
    }
  },
  computed:{
    ...mapState({
      railroad: 'editRailroadTemporaryActive',
      apiToken: 'apiToken',
    }),
  },
  created() {
    moment.locale(this.$i18n.locale)
  },
  mounted() {
    this.$modal.show('railroad-temporary-active-edit')
  },

  methods: {
    editRailroadTemporaryActiveSubmit() {
      const params = {
        disable_active_until: moment().add(this.railroad_disable_active_in_minutes, 'minutes').toISOString(),
        active: false,
      }
      $.ajax({
        url: `/railroads/${this.railroad.id}/edit_temporary_active`,
        data: params,
        method: 'put',
        headers: { 'X-AUTH-TOKEN': this.apiToken },
      }).always((result) => {
        if (result.status === 'ok') {
          this.$store.dispatch('refreshRailroads', [{...this.railroad, ...params}])
          succNotify({ title: this.$t('success') })
          this.onClosed()
        } else {
          errNotify({ title: result.error })
        }
      })
    },
    onClosed() {
      this.$emit('closed')
      this.$store.dispatch('hideTemporaryDisableActiveRailroadModal')
    },
    closeModal() {
      this.$modal.hide('railroad-temporary-active-edit')
    },
  },
}
</script>
