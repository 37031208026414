<template lang="pug">
div
  .ui.grid
    .three.wide.column
      vSelect(
        class='ui-vue-select'
        :options='plantsOptions'
        :multiple='true'
        v-model='selectedPlants'
        v-bind:placeholder='$t("plant")'
      )
    .three.wide.column
      vSelect(
        class='ui-vue-select'
        :options='statusesOptions'
        :multiple='true'
        v-model='selectedStatuses'
        v-bind:placeholder='$t("statuses")'
      )
    .three.wide.column
      DatePicker(
        lang="it",
        v-model="fromDate",
        format="DD/MM/YYYY",
        clearable=false,
        type="date",
        placeholder="Da",
        value-type="DD/MM/YYYY"
      )
    .three.wide.column
      DatePicker(
        lang="it",
        v-model="toDate",
        format="DD/MM/YYYY",
        clearable=false,
        type="date",
        placeholder="A",
        value-type="DD/MM/YYYY"
      )
    .three.wide.column
      .ui.buttons
        .ui.tiny.button.blue(v-on:click='reloadData')
          | {{ $t('search') }}
  .ui.grid
    .ui.buttons
      .ui.tiny.button.green(v-on:click='pairOperations')
        | {{ $t('pair') }}
      .ui.tiny.button.blue(v-on:click='collapsePairOperations' v-if="showPairOperations")
        | {{ $t('collapse') }}
      .ui.tiny.button.blue(v-on:click='uncollapsePairOperations' v-else)
        | {{ $t('show') }}
    .row.padded(v-if="!showPairOperations")
  .ui.grid
    .row.padded(v-show="showPairOperations")
      .eight.wide.column
        h3
          | {{ $t('arrival') }}
        PairingTableV2(
          :operations='arrivalOperations'
          v-on:toggleOperationToPair='toggleOperationToPair'
        )
        PaginationV2(
          :page='arrivalPage'
          v-on:pageChanged='getArrivalOperations'
        )
      .eight.wide.column
        h3
          | {{ $t('departure') }}
        PairingTableV2(
          :operations='departureOperations'
          v-on:toggleOperationToPair='toggleOperationToPair'
        )
        PaginationV2(
          :page='departurePage'
          v-on:pageChanged='getDepartureOperations'
        )
    .ui.buttons.padded
      .ui.tiny.button.red(v-on:click='unpairOperations')
        | {{ $t('unpair') }}
      .ui.tiny.button.blue(v-on:click='collapseUnpairOperations' v-if="showUnpairOperations")
        | {{ $t('collapse') }}
      .ui.tiny.button.blue(v-on:click='uncollapseUnpairOperations' v-else)
        | {{ $t('show') }}
    .row.padded(v-show="showUnpairOperations")
      .twelve.wide.column
        h3
          | {{ $t('odm_pairs') }}
        PairedOperationsTableV2(
          :operations='pairedOperations'
          v-on:saveOperationsToUnpair='saveOperationsToUnpair'
        )
        PaginationV2(
          :page='pairsPage'
          v-on:pageChanged='getPairedOperations'
        )


</template>

<script>
import PairingTableV2 from './PairingTableV2.vue'
import PairedOperationsTableV2 from './PairedOperationsTableV2.vue'
import PaginationV2 from './PaginationV2.vue'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  components: {
    PaginationV2,
    PairingTableV2,
    PairedOperationsTableV2,
    DatePicker,
    vSelect,
  },
  data() {
    return {
      arrivalOperations: [],
      departureOperations: [],
      operationsToPair: [],
      pairedOperations: [],
      operationsToUnpair: [],
      arrivalPage: 1,
      departurePage: 1,
      pairsPage: 1,
      fromDate: '',
      toDate: '',
      selectedPlants: [],
      selectedStatuses: [],
      plantsOptions: [],
      statusesOptions: [],
      showPairOperations: true,
      showUnpairOperations: true,
    }
  },
  mounted() {
    this.setPlantsOptions()
    this.setStatusesOptions()
    this.setDefaultDate()
  },
  watch: {
    statusesOptions() {
      this.setDefaultStatus()

      // apply filters after setting default status
      this.reloadData()
    },
  },
  methods: {
    setPlantsOptions() {
      this.$http.get('/plants/list_for_select.json').then(response => {
        response.data.plants.forEach((plant) => {
          this.plantsOptions.push({ label: plant.name, code: plant.value })
        })
      })
    },
    setStatusesOptions() {
      this.$http.get('/plants/statuses_for_select.json').then(response => {
        this.statusesOptions = response.data.plants
      })
    },
    setDefaultStatus() {
      this.statusesOptions.forEach((status) => {
        if (status['code'] === 'provisional')
          this.selectedStatuses.push(status)
      })
    },
    setDefaultDate() {
      var today = new Date()
      today = moment(today).format('DD/MM/YYYY')
      this.fromDate = today
      this.toDate = today
    },
    reloadData() {
      this.getArrivalOperations(this.arrivalPage)
      this.getDepartureOperations(this.departurePage)
      this.getPairedOperations(this.pairsPage)
    },
    pairOperations() {
      if (this.operationsToPair.length == 2) {
        this.$http.post(
          '/operations_pairings/pair_operations',
          { operationsToPair: this.operationsToPair }).then(response => {
            if (response.data['success']) {
              this.operationsToPair.forEach((pairedOperationId) => {
                this.arrivalOperations = this.arrivalOperations.filter(operation => operation.id !== pairedOperationId)
                this.departureOperations = this.departureOperations.filter(operation => operation.id !== pairedOperationId)
              })
              this.getPairedOperations(this.pairsPage)
              this.operationsToPair = []
            }
          })
      }
    },
    unpairOperations() {
      this.$http.post(
        '/operations_pairings/unpair_operations',
        { operationsToUnpair: this.operationsToUnpair }).then(response => {
          if (response.data['success']) {
            this.getPairedOperations(this.pairsPage)
            this.getArrivalOperations(this.arrivalPage)
            this.getDepartureOperations(this.departurePage)
            this.operationsToUnpair = []
          }
        })
    },
    collapsePairOperations() {
      this.showPairOperations = false
    },
    uncollapsePairOperations() {
      this.showPairOperations = true
    },
    collapseUnpairOperations() {
      this.showUnpairOperations = false
    },
    uncollapseUnpairOperations() {
      this.showUnpairOperations = true
    },
    saveOperationsToUnpair(data) {
      this.operationsToUnpair = data
    },
    toggleOperationToPair(data) {
      var oldOperationId = data['oldOperationId']
      var newOperationId = data['newOperationId']
      if (newOperationId) {
        if (this.operationsToPair.includes(oldOperationId)) {
          this.operationsToPair = this.operationsToPair.filter(operationId => operationId !== oldOperationId)
        }
        this.operationsToPair.push(newOperationId)
      } else {
        this.operationsToPair = this.operationsToPair.filter(operationId => operationId !== oldOperationId)
      }
    },
    getSelectedPlantsIds() {
      var selectedIds = []
      this.selectedPlants.forEach((plant) => {
        selectedIds.push(plant['code'])
      })
      return selectedIds
    },
    getStatusesIds() {
      var selectedIds = []
      this.selectedStatuses.forEach((status) => {
        selectedIds.push(status['code'])
      })
      return selectedIds
    },
    getArrivalOperations(page) {
      var query = {
        typology_eq: 0,
        done_on_gteq: this.fromDate,
        done_on_lteq: this.toDate,
        plant_id_in: this.getSelectedPlantsIds(),
        cached_order_status_in: this.getStatusesIds(),
      }
      this.$http.get(
        `/operations_pairings_v2/operations_collection?page=${page}`,
        { params: { q: query, paired: false } }
      ).then(response => {
        this.arrivalPage = page
        this.arrivalOperations = response.data
      })
    },
    getDepartureOperations(page) {
      var query = {
        typology_eq: 1,
        done_on_gteq: this.fromDate,
        done_on_lteq: this.toDate,
        plant_id_in: this.getSelectedPlantsIds(),
        cached_order_status_in: this.getStatusesIds(),
      }
      this.$http.get(
        `/operations_pairings_v2/operations_collection?page=${page}`,
        { params: { q: query, paired: false } }
      ).then(response => {
        this.departurePage = page
        this.departureOperations = response.data
      })
    },
    getPairedOperations(page) {
      this.$http.get(
        `/operations_pairings_v2/operations_collection?page=${page}`,
        { params: { paired: true } }
      ).then(response => {
        this.pairsPage = page
        this.pairedOperations = response.data
      })
    },
  },
}
</script>
