<template lang='pug'>
  .railroad-group
    .railroad-group__header(v-on:click='handleCollapseClick' v-bind:class='cssHeaderClass')
      .railroad-group__ctas(v-if='isCollapsablePlantViewEnabled')
        button.ui.icon.button.circular.mini(v-bind:class='cssCollaspableClass')
          i.plus.icon
      .ui.header.attached.top
        .grid.ui
          .five.wide.mobile.two.wide.tablet.two.wide.computer.column
            h4
              | {{ railroadGroup.name }}
          .four.wide.mobile.two.wide.tablet.two.wide.computer.column
            .text-left
              | {{ railroadGroup.insert_from_left_label }}
          .mobile.hidden.nine.wide.column
          .four.wide.mobile.two.wide.tablet.two.wide.computer.column
            .text-right
              | {{ railroadGroup.insert_from_right_label }}
    .ui.bottom.attached.segment(v-if='isUncollapsed || foundBySearchedParams')
      div(v-if='hasRailroads')
        Railroad(v-for='railroad in railroads'
                 v-bind:key='railroad.id'
                 v-bind:railroad='railroad'
                 v-bind:searchedCarCode='searchedCarCode'
                 v-bind:searchedFullCarCodes='searchedFullCarCodes'
                 v-bind:searchedCarTypology='searchedCarTypology'
                 v-bind:searchedMaintenanceStatus='searchedMaintenanceStatus'
                 :readOnlyPlant='readOnlyPlant'
                 :currentDate='currentDate'
                 :minimizeNote='true')
      p.no-railroads(v-else) {{ $t('no_railroads_warning') }}
</template>

<script>
import Railroad from './railroad'
import { queryStringToJSON } from '../utils/querystring-to-json'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    Railroad,
  },

  props: {
    railroadGroup: Object,
    searchedCarCode: String,
    searchedFullCarCodes: Array,
    searchedCarTypology: Object,
    searchedMaintenanceStatus: Object,
    readOnlyPlant: Boolean,
    currentDate: String,
  },

  data() {
    return {
      collapsed: null,
    }
  },

  updated() {
    $('#plant-container div[data-popup-trigger]').popup({
      inline: true,
    })
  },

  computed: {
    ...mapState([
      'tenant',
      'isRailRoadGroupCollapsed',
    ]),
    ...mapGetters([
      'isCollapsablePlantViewEnabled',
      'currentAddonable',
    ]),
    isCollapsed() {
      if (!this.isCollapsablePlantViewEnabled) { return false }

      // Handle initial state here when collapsed is null
      if (this.collapsed === null) {

        // uncollapse if required by queryparams
        const queryStringObj = queryStringToJSON(window.location.search)
        if (queryStringObj && queryStringObj.uncollapse_railroad_group && this.railroadGroup.id === queryStringObj.uncollapse_railroad_group) {
          this.collapsed = false
          return this.collapsed
        }

        const addonableRailroad = this.currentAddonable && this.currentAddonable.car_list_railroad || {}
        const isCurrentAddonableRailradGroup = Number(this.railroadGroup.id) === Number(addonableRailroad.railroad_group_id)

        return (
          !this.railroadGroup.initial_uncollapsed_view
          && !isCurrentAddonableRailradGroup
        )
      }

      return !!this.collapsed
    },
    isUncollapsed() {
      if (this.collapsed === null && this.hasCarList && this.tenant !== 'sncfr') {
        this.collapsed = false
        return true
      }
      if(this.tenant === 'sncfr' && this.isRailRoadGroupCollapsed && this.hasCarList && (this.collapsed || this.collapsed === null)) {
        this.collapsed = !this.collapsed
        return true
      }
      return !this.isCollapsed
    },
    cssHeaderClass() {
      return this.isCollapsed ? 'collapsed' : 'uncollapsed'
    },
    cssCollaspableClass() {
      return this.isCollapsed ? 'blue collapsed' : 'yellow uncollapsed'
    },
    railroads() {
      return this.railroadGroup.railroads
    },
    hasRailroads() {
      return this.railroads !== undefined &&
        this.railroads !== null &&
        this.railroads.length > 0
    },
    foundBySearchedParams() {
      if (!(this.searchedCarCode || this.searchedCarTypology || this.searchedMaintenanceStatus)) {
        return false // not searching at all
      }

      const foundByCode = !!this.searchedCarCode &&
        this.railroadGroup &&
        this.railroadGroup.railroads &&
        // at least one found in the flat array of each railroads.car_lists.cars.code
        !!(this.railroadGroup.railroads.flatMap(rr => rr.car_lists.flatMap(cl =>cl.cars.flatMap(car=> car.code)))
          .find(code => code.includes(this.searchedCarCode)))

      const foundByTypology = !!this.searchedCarTypology &&
        this.railroadGroup &&
        this.railroadGroup.railroads &&
        // at least one equal in the flat array of each railroads.car_lists.cars.sncf_wagon_type
        !!(this.railroadGroup.railroads.flatMap(rr => rr.car_lists.flatMap(cl =>cl.cars.flatMap(car=> car.sncf_wagon_type)))
          .find(sncf_wagon_type => sncf_wagon_type === this.searchedCarTypology.code))

      const foundByMaintenanceStatus = !!this.searchedMaintenanceStatus &&
        this.railroadGroup &&
        this.railroadGroup.railroads &&
        // at least one equal in the flat array of each railroads.car_lists.cars.car_typology_id
        !!(this.railroadGroup.railroads.flatMap(rr => rr.car_lists.flatMap(cl =>cl.cars.flatMap(car=> car.maintenance_status_from_verform_api)))
          .find(maintenance_status_from_verform_api => maintenance_status_from_verform_api === this.searchedMaintenanceStatus.id))

      // not searching by X or searching and found by X
      return (!this.searchedCarCode || foundByCode) &&
        (!this.searchedCarTypology || foundByTypology) &&
        (!this.searchedMaintenanceStatus || foundByMaintenanceStatus)
    },
    hasCarList() {
      return this.railroadGroup.railroads.some(railroad =>
        railroad.car_lists.some(car_list => car_list.cars.length > 0))
    },
  },
  methods: {
    handleCollapseClick(e) {
      e.preventDefault()

      this.collapsed = !this.isCollapsed
    },
  },
}
</script>
