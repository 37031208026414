import movementsApi from '../../api/movements'
import { railroadBookingInfo } from '../../utils/railroad-booking-info'

export default {
  railroadBookingInfo(context, { railroadId, railroadGroupId, currentDate }) {
    railroadBookingInfo(railroadId, railroadGroupId, currentDate, context)
  },
  hideRailroadCarListsMergeModal(context) {
    context.commit('hideRailroadCarListsMergeModal')
  },

  showRailroadCarListsMergeModal(context, { railroad }) {
    context.commit('showRailroadCarListsMergeModal', { railroad })
  },

  railroadCarListsMerge(context, { carListCode, trainId, beforeDeselection, railroadId }) {
    const railroad = context.getters.getRailroad(railroadId)

    const apiParams = {
      carListCode,
      trainId,
      railroadId,
      operationId:         context.getters.currentOperationId,
      addonableId:         context.getters.currentAddonableId,
      apiToken:            context.state.apiToken,
      railroadUpdateToken: railroad.update_token,
    }

    return movementsApi.mergeAll(
      apiParams,
      ({railroads, plantUpdateToken}) => {
        context.dispatch('refreshRailroads', railroads)
        context.dispatch('refreshplantUpdateToken', plantUpdateToken)
        context.dispatch('reloadAddonablesForm')

        if (beforeDeselection) { beforeDeselection() }

        context.dispatch('hideRailroadCarListsMergeModal') // in case, close edit modal
      },
      error => { console.error(error) }
    )
  },
}

