export default {
  hideRailroadCarListsMergeModal(state) {
    state.bulkRailroadCarListsMerge = false
  },

  showRailroadCarListsMergeModal(state, { railroad }) {
    state.bulkRailroadCarListsMerge = railroad
  },

  railroadBookingInfo(state, { info }) {
    state.bookedByResponse = info
  },
}

