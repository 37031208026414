// import Api from '../../api/car_lists'
import { fetchRailroad } from '../../api/railroads'
import movementsApi from '../../api/movements'
import plantsApi from '../../api/plants'

export default {
  carListRelease(context, params) {
    let railroad = context.getters.getRailroadByCarListUuid(params.carListUuid)
    let plantId = null
    if (params.otherPlant !== undefined && params.otherPlant !== null) {
      plantId = params.otherPlant.id
    }

    const successCallback = ({ railroads, plantUpdateToken }) => {
      context.dispatch('refreshRailroads', railroads)
      context.dispatch('refreshplantUpdateToken', plantUpdateToken)
      context.commit('releaseOperation', context.getters.currentOperationId)
    }
    const errorCallback = error => { console.error(error) }

    return movementsApi.release(
      context.state.apiToken,
      railroad.id,
      railroad.update_token,
      context.getters.currentOperationId,
      params.carListUuid,
      params.trainId,
      params.manualTrainNumber,
      params.notes,
      { plantId, trainDate: params.trainDate, trainTime: params.trainTime },
      successCallback,
      errorCallback
    )
  },

  showUploadingModal(context, carList) {
    context.commit('uploadingCarList', carList)
  },

  hideUploadingModal(context) {
    context.commit('uploadingCarList', null)
  },

  showCarListSplitAtModal(context, carList) {
    context.commit('splittingCarList', carList)
  },

  hideCarListSplitAtModal(context) {
    context.commit('splittingCarList', null)
  },

  hideHazardModal(context) {
    context.commit('hideHazardModal', null)
  },

  showRatConfirmatorModal(context, carList) {
    context.commit('ratConfirmatorModal', carList)
  },

  hideRatConfirmatorModal(context) {
    context.commit('ratConfirmatorModal', null)
  },

  showPublishingModal(context, carList) {
    context.commit('publishingCarList', carList)
  },

  hidePublishingModal(context) {
    context.commit('publishingCarList', null)
  },

  showCarRemoverModal(context, args){
    context.commit('removingCarFromList', args)
  },

  hideCarRemoverModal(context){
    context.commit('removingCarFromList', {carLists: null, railroad: null})
  },

  setWagonsManagementModalMode ({ commit }, mode) {
    commit('setWagonsManagementModalMode', mode)
  },

  showRailroadNoteModal(context, args){
    context.commit('editingRailroadNote', args)
  },
  showTemporaryDisableActiveRailroadModal(context, args){
    context.commit('editingRailroadTemporaryActive', args)
  },
  hideRailroadNoteModal(context){
    context.commit('editingRailroadNote', null)
  },
  hideTemporaryDisableActiveRailroadModal(context){
    context.commit('editingRailroadTemporaryActive', null)
  },

  carListsMergeModal(context, params) {
    context.commit('mergingCarList', params)
  },

  carListsMerge(context, params) {
    let railroad
    const { carListCode, trainId, beforeDeselection } = params

    if (params.railroadId !== undefined) {
      railroad = context.getters.getRailroad(params.railroadId)
    } else {
      // if railroad id is not available, find it from car list id (slower...)
      railroad = context.getters.getRailroadByCarListUuid(params.leftCarListUuid)
    }

    return movementsApi.merge(
      context.state.apiToken, railroad.id, railroad.update_token,
      context.getters.currentOperationId, params.leftCarListUuid, params.rightCarListUuid,
      {
        carListCode,
        trainId,
        addonableId: context.getters.currentAddonableId,
      },
      ({ railroads, plantUpdateToken }) => {
        context.dispatch('refreshRailroads', railroads)
        context.dispatch('refreshplantUpdateToken', plantUpdateToken)
        context.dispatch('reloadAddonablesForm')

        if (beforeDeselection) { beforeDeselection() }

        context.commit('editingCarList', null) // in case, close edit modal
      },
      error => { console.error(error) }
    )
  },

  highlightCarList(context, carList) {
    context.commit('highlightCarList', carList)
  },

  setCarList(context, carList) {
    context.commit('setCarList', carList)
  },

  fetchAndEditCarList(context, { railroadId, carListId }) {
    fetchRailroad({ apiToken: context.state.apiToken, railroadId })
      .then(result => {
        context.dispatch('refreshRailroads', [result.railroad])
        context.dispatch('editCarList', carListId)
      })
  },

  editCarList(context, carListId) {
    const found = (context.state.railroadGroups || []).some(rrg => {
      const rrResult = (rrg.railroads || []).some(rr => {
        const clResult = rr.car_lists.some(carList => {
          if (carList.id === carListId) {
            context.commit('editingCarList', carList)

            return true
          }
        })

        if (clResult) { return true }
      })

      if (rrResult) { return true }
    })

    if (!found) {
      context.commit('editingCarList', null)
    }
  },

  carListInvert(context, params) {
    const railroad = context.getters.getRailroad(params.railroadId)
    const operationId = context.getters.currentOperationId

    return movementsApi.invert(
      context.state.apiToken,
      railroad.id,
      railroad.update_token,
      params.carListUuid,
      operationId,
      ({ railroads, plantUpdateToken }) => {
        context.dispatch('refreshRailroads', railroads)
        context.dispatch('refreshplantUpdateToken', plantUpdateToken)
        context.commit('editingCarList', null)
      },
      error => {
        console.error(error)
      }
    )
  },

  carListClamps(context, params) {
    const railroad = context.getters.getRailroadByCarListUuid(
      params.carListUuid
    )
    const operationId = context.getters.currentOperationId
    return movementsApi.clamps(
      context.state.apiToken,
      railroad.id,
      railroad.update_token,
      params.carListUuid,
      params.clampsValue,
      params.leftBracketId,
      params.rightBracketId,
      params.leftCarPositionId,
      params.rightCarPositionId,
      operationId,
      { addonableId: context.getters.currentAddonableId },
      ({ railroads, plantUpdateToken }) => {
        context.dispatch('refreshRailroads', railroads)
        context.dispatch('refreshplantUpdateToken', plantUpdateToken)
        plantsApi.show(context.state.apiToken, context.state.plantId,
          data => { context.commit('refreshPlant', data.plant) },
          error => { console.log(error) }
        )
      },
      error => { console.error(error) }
    )
  },

  clampsLocomotive(context, params) {
    const { getters, state, dispatch, commit } = context
    const {
      locomotive, clampsValue, leftBracketId, rightBracketId , leftCarPositionId, rightCarPositionId
    } = params
    const { apiToken } = state
    const carListUuid = locomotive.car_list_uuid
    const carPositionId = locomotive.id
    const railroad = getters.getRailroadByCarListUuid(carListUuid)
    const operationId = getters.currentOperationId
    const addonableId = getters.currentAddonableId

    const onFailure = console.error
    const onSuccess = ({ railroads, plantUpdateToken }) => {
      dispatch('refreshRailroads', railroads)
      dispatch('refreshplantUpdateToken', plantUpdateToken)
      plantsApi.show(state.apiToken, state.plantId,
        data  => { commit('refreshPlant', data.plant) },
        onFailure
      )
    }

    return movementsApi.clampsLocomotive(
      {
        apiToken,
        railroadId: railroad.id,
        railroadUpdateToken: railroad.update_token,
        operationId,
        addonableId,
        carListUuid,
        carPositionId,
        clampsValue,
        leftBracketId,
        rightBracketId,
        leftCarPositionId,
        rightCarPositionId,
      },
      { onSuccess, onFailure }
    )
  },

  carListSplit(context, params) {
    let railroad = context.getters.getRailroadByCarListUuid(params.carListUuid)
    const successCallback = ({ railroads, plant_update_token, ...data }) => {
      context.dispatch('refreshRailroads', railroads)
      context.dispatch('refreshplantUpdateToken', plant_update_token)

      if (data.uuidToEditAgain !== undefined && data.uuidToEditAgain !== '' && data.uuidToEditAgain !== null) {
        context.commit('editingCarList', context.getters.getCarList(data.uuidToEditAgain))
      } else {
        if (params.beforeDeselection) {
          params.beforeDeselection()
        }

        context.commit('editingCarList', null)
      }
    }
    const errorCallback = console.error

    return movementsApi.split(
      context.state.apiToken,
      railroad.id,
      railroad.update_token,
      context.getters.currentOperationId,
      params.carListUuid,
      params.splitIndex,
      params.splitAgain,
      {
        car_lists_codes: params['codes'],
        car_lists_notes: params['notes'],
        addonableId: context.getters.currentAddonableId,
      },
      successCallback,
      errorCallback
    )
  },

  carListPopAt(context, params) {
    let railroad = context.getters.getRailroadByCarListUuid(params.carListUuid)
    const onSuccess = ({ railroads, plant_update_token, ...data }) => {
      context.dispatch('refreshRailroads', railroads)
      context.dispatch('refreshplantUpdateToken', plant_update_token)

      if (params.beforeDeselection) {
        params.beforeDeselection()
      }

      context.dispatch('hideCarListSplitAtModal')
    }
    const onFailure = console.error

    return movementsApi.popResourceAt({
      apiToken: context.state.apiToken,
      railroadId: railroad.id,
      railroadUpdateToken: railroad.update_token,
      operationId: context.getters.currentOperationId,
      carListUuid: params.carListUuid,
      splitIndex: params.splitIndex,
      carListsCodes: params['codes'],
      carListsNotes: params['notes'],
      addonableId: context.getters.currentAddonableId,
      onSuccess,
      onFailure,
    })
  },

  carListSplitAll(context, { carListUuid, ...params }) {
    let railroad = context.getters.getRailroadByCarListUuid(carListUuid)

    return movementsApi.splitAll(
      context.state.apiToken,
      railroad.id,
      railroad.update_token,
      context.getters.currentOperationId,
      carListUuid,
      { addonableId: params.addonableId || context.getters.currentAddonableId },
      ({ railroads, plantUpdateToken }) => {
        context.dispatch('refreshRailroads', railroads)
        context.dispatch('refreshplantUpdateToken', plantUpdateToken)

        if (params.beforeDeselection) {
          params.beforeDeselection()
        }

        context.commit('editingCarList', null) // close edit modal
      },
      error => { console.error(error) }
    )
  },
}
