<template lang="pug">
.pairing-table
  .table-container-scrollable
    table.ui.compact.table.unstackable.max-content
      thead
        th
        th {{ $t('train') }}
        th {{ $t('from_to') }}
        th {{ $t('mto') }}
        th {{ $t('if') }}
        th {{ $t('holder') }}
        th {{ $t('hour') }}
        th {{ $t('date') }}
        th {{ $t('notes') }}
      tbody
        tr(v-if='!operationsData.length')
          td(colspan=8)
            .text-center
              | {{ $t('no_data_found') }}
        tr(v-for="(operation) in operationsData")
          td: input(
            type="checkbox",
            :id="operation[0].id",
            :value="operation[0].id",
            v-model="checkedOperations"
          )
          td {{ operation[0].train }} - {{ operation[1].train }}
          td {{ operation[0].station }} - {{ operation[1].station }}
          td {{ operation[0].mto_name }} - {{ operation[1].mto_name }}
          td {{ operation[0].railway_company_name }} - {{ operation[1].railway_company_name }}
          td {{ operation[0].holder_company_name }} - {{ operation[1].holder_company_name }}
          td {{ formatProgrammedHour(operation[0].programmed_hour) }} - {{ formatProgrammedHour(operation[1].programmed_hour) }}
          td {{ formatDoneOn(operation[0].done_on) }} - {{ formatDoneOn(operation[1].done_on) }}
          td {{ operation[0].notes }} - {{ operation[1].notes }}

      tfoot.full-width
</template>

<script>
import Pagination from '../pagination/Pagination.vue'
import moment from 'moment'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      operationsData: [],
      checkedOperations: [],
    }
  },
  props: {
    operations: Array,
  },
  methods: {
    formatProgrammedHour(programmed_hour) {
      if (programmed_hour) return `${moment(programmed_hour).format('HH:mm')}`
    },
    formatDoneOn(doneOn) {
      if (doneOn) return `${moment(doneOn).format('DD/MM/YYYY')}`
    },
    organizeOperations(operations) {
      var organizedOperationIds = []
      var organizedOperations = []
      operations.forEach((operation) => {
        if (!organizedOperationIds.includes(operation.id) && !organizedOperationIds.includes(operation.paired_operation_id)) {
          var paired_operation = operations.filter(op => op.id === operation.paired_operation_id)[0]
          if (operation.typology === 'arrival') {
            organizedOperations.push([operation, paired_operation])
          } else {
            organizedOperations.push([paired_operation, operation])
          }
          organizedOperationIds.push(operation.id)
          organizedOperationIds.push(operation.paired_operation_id)
        }
      })
      return organizedOperations
    },
  },
  mounted() {
    // at this point this.operations is empty
    this.operationsData = this.organizeOperations(this.operations)
  },
  watch: {
    operations(newOperations) {
      this.checkedOperations = []
      // operations can be updated from outside
      this.operationsData = this.organizeOperations(newOperations)
    },
    checkedOperations(newOperations) {
      this.$emit('saveOperationsToUnpair', newOperations)
    },
  },
}
</script>

<style lang="sass" scoped>
.pairing-table
  .max-content
    width: max-content !important

  .table-container-scrollable
    max-height: 79vh
</style>
