import Vue from 'vue'
import _ from 'underscore'
import movementsApi from '../api/movements'
import plantsApi from '../api/plants'
import uuidv4 from 'uuid/v4'

// Railroad
import railroadsGetters from './railroads/getters'
import railroadsActions from './railroads/actions'
import railroadsMutations from './railroads/mutations'

// Operation addonables
import addonablesActions from './addonables/actions'
import addonablesMutations from './addonables/mutations'

// Car Lists states
import carListsActions from './car_lists/actions'
import carListsMutations from './car_lists/mutations'

// Settings
import settingsGetters from './settings/getters'

import { setPlantStorage } from '../utils/localStorage'

import { error as errNotify } from '../../utils/notify'

export const state = {
  plantId: null,
  plantName: '',
  plantUpdateToken: null,
  railroadGroups: [],
  operations: [],
  addonables: [],
  otherPlants: [],
  manualTrainSelect: false,
  releaseToAnotherPlant: false,
  createOperationEnabled: false,
  loading: false,
  isOperationsDropdownShown: false,
  currentOperation: null,
  currentAddonable: null,
  currentCarList: null,
  highlightedCarList: null,
  apiToken: null,
  bulkRailroadCarListsMerge: false,
  mergingCarList: null,
  mergingCarListDirection: null,
  editingCarList: null,
  splittingCarList: null,
  releasingCarList: null,
  removingCarFromList: null,
  editRailroadNote: null,
  editRailroadTemporaryActive: null,
  ratConfirmatorModal: null,
  dragging: false,
  draggingCarListId: null,
  // datepicker state
  currentDate: null,
  currentOperationType: null,
  originalDate: null,
  plantBracketsCount: null,
  plantBracketsAvailableCount: null,
  plantInsertFromLabels: {},
  addonablesCollectionFormContent: null,
  addonablesCollectionFormIsLoading: false,
  addonablesFormContent: null,
  addonablesFormIsLoading: false,
  addonablesFormIsShown: false,
  publishingCarList: null,
  uploadingCarList: null,
  settings: {},
  currentUser: null,
  tenant: null,
  showPlantCarListPublishingModal: false,
  showHazardModal: false,
  canToggleRailroadActive: false,
  permissionToMergeRailroadCarLists: false,
  bookedByResponse: null,
  canWriteRailroad: false,
  canWriteCarPosition: false,
  carSearchResultsFound: true,
  wagonsManagementModalMode: 'remove',
  insufficientRailRoadCapacityWarning: null,
  isRailRoadGroupCollapsed: false,

}

export const getters = {
  ...railroadsGetters,
  ...settingsGetters,
  isRailwayCompanyUser(state) {
    const { currentUser } = state

    if (!currentUser) { return false }

    return !!currentUser.is_railway_company_user
  },
  currentCarList(state) {
    return state.currentCarList
  },
  currentPlantId(state) {
    return state.plantId
  },
  currentOperationTitle(state, getters) {
    if (!getters.isOperationSelected) { return null }

    return state.currentOperation.title
  },
  // Plant manipulation disabled
  manipulationDisabled(state, getters) {
    if (getters.isOperationSelected) {
      return state.currentOperation.manipulation_disabled
    }

    if (!state.settings.deny_movements_without_operations) {
      return false
    }

    return !getters.isOperationSelected
  },
  isOperationsDropdownShown(state) {
    return state.isOperationsDropdownShown
  },
  isPlannedShuntingOperation({ currentOperation }) {
    return currentOperation && currentOperation.typology === 'planned_shunting'
  },
  isOperationSelected(state) {
    return state.currentOperation !== undefined && state.currentOperation !== null
  },
  willRenderAddonablesSidebar(state, getters) {
    return getters.isOperationSelected && !getters.isPlannedShuntingOperation
  },
  currentOperationId(state, getters) {
    return getters.isOperationSelected ? state.currentOperation.id : null
  },
  isAddonableSelected(state) {
    return state.currentAddonable !== undefined && state.currentAddonable !== null
  },
  currentAddonableId(state, getters) {
    return getters.isAddonableSelected ? state.currentAddonable.id : null
  },
  currentAddonable(state, getters) {
    return getters.isAddonableSelected ? state.currentAddonable : null
  },
  carLists(state) {
    let carLists = []

    state.railroadGroups.forEach(function(rg) {
      rg.railroads.forEach(function(r) {
        carLists = carLists.concat(r.car_lists)
      })
    })

    return carLists
  },
  plantCars(state) {
    let cars = []

    state.railroadGroups.forEach(function(rg) {
      rg.railroads.forEach(function(r) {
        r.car_lists.forEach(function(carList) {
          cars = cars.concat(carList.cars)
        })
      })
    })

    return cars
  },
  getRailroadCarLists: (state) => (railroadId) => {
    let carLists = []
    state.railroadGroups.forEach(function(rg) {
      rg.railroads.forEach(function(r) {
        if (r.id === railroadId) {
          carLists = r.car_lists
        }
      })
    })
    return carLists
  },
  getRailroad: (state) => (railroadId) => {
    let foundRailroad

    state.railroadGroups.forEach(function(railroadGroup) {
      if (foundRailroad !== undefined) return
      railroadGroup.railroads.forEach(function(railroad) {
        if (foundRailroad !== undefined) return
        if (Number(railroad.id) === Number(railroadId)) {
          foundRailroad = railroad
        }
      })
    })

    return foundRailroad
  },
  getRailroadByCarListUuid: (state) => (carListUuid) => {
    let foundRailroad
    state.railroadGroups.forEach(function(railroadGroup) {
      if (foundRailroad !== undefined) return
      railroadGroup.railroads.forEach(function(railroad) {
        if (foundRailroad !== undefined) return
        let foundCarList = railroad.car_lists.find((carList) => {
          return carList.uuid === carListUuid
        })
        if (foundCarList !== undefined) {
          foundRailroad = railroad
        }
      })
    })
    return foundRailroad
  },
  getCarList: (state) => (carListUuid) => {
    let foundCarList
    state.railroadGroups.forEach(function(railroadGroup) {
      if (foundCarList !== undefined) return
      railroadGroup.railroads.forEach(function(railroad) {
        if (foundCarList !== undefined) return
        foundCarList = railroad.car_lists.find((carList) => {
          return carList.uuid === carListUuid
        })
      })
    })
    return foundCarList
  },
  // Car List unavailable for Addonables operations
  isCarListViewable: (state, getters) => (carList) => {
    const { isRailwayCompanyUser } = getters
    if (!isRailwayCompanyUser) { return true }

    const { currentUser } = state

    return carList.railway_company_id == currentUser.railway_company_id
  },
  // Car List unavailable for Addonables operations
  carListUnavailable: (state, getters) => (carList) => {
    if (!carList.current_addonable) { return false }

    return Number(getters.currentAddonableId) !== Number(carList.current_addonable.id)
  },
}

export const actions = {
  ...railroadsActions,
  ...addonablesActions,
  ...carListsActions,
  loadInitialState(context, initialProps) {
    context.commit('loadInitialState', initialProps)
    context.commit('sortRailroadsCarlists')
  },
  refreshRailroads(context, data) {
    context.commit('refreshRailroads', data)
    context.commit('sortRailroadsCarlists')
  },
  refreshplantUpdateToken(context, plantUpdateToken) {
    context.commit('refreshplantUpdateToken', plantUpdateToken)
    plantsApi.plantUpdated({
      apiToken: context.state.apiToken,
      plantId: context.state.plantId,
      plantUpdateToken: plantUpdateToken,
    })
  },
  selectOperation(context, operation) {
    context.commit('addonablesFormShown', false)
    context.commit('selectOperation', operation)
    context.dispatch('loadAddonablesCollectionForm', operation)
  },
  showOperationsDropdown(context) {
    context.commit('showOperationsDropdown')
  },
  hideOperationsDropdown(context) {
    context.commit('hideOperationsDropdown')
  },
  selectFirstOperation(context) {
    if (context.state.operations.length === 0) return
    context.dispatch('selectOperation', context.state.operations[0])
  },
  carListProcessing(context, params) {
    let railroad = context.getters.getRailroadByCarListUuid(params.carListUuid)

    return movementsApi.processing(
      context.state.apiToken,
      railroad.id,
      railroad.update_token,
      params.carListUuid,
      params.processingValue,
      ({ railroads, plantUpdateToken }) => {
        context.dispatch('refreshRailroads', railroads)
        context.dispatch('refreshplantUpdateToken', plantUpdateToken)
      },
      error => {
        console.error('carListProcessing', error)
      }
    )
  },
  deselectOperation(context) {
    context.dispatch('bulkProcessing', false)
    context.commit('unloadOperation')
    context.commit('setAddonables', [])
    context.dispatch('hideOperationsDropdown')
  },
  bulkProcessing(context, processing) {
    let railroadsTokens = []
    context.state.railroadGroups.forEach(function(railroadGroup) {
      railroadGroup.railroads.forEach(function(railroad) {
        if (railroad.car_lists.length > 0 && railroad.update_token !== null) {
          railroadsTokens.push({
            id: railroad.id,
            update_token: railroad.update_token,
          })
        }
      })
    })

    plantsApi.bulkProcessing(context.state.apiToken, context.state.plantId, processing, railroadsTokens,
      data => { context.dispatch('refreshRailroads', data) },
      error => { console.log(error) }
    )
  },
  carListDropped(context, { event, ...options }) {
    let fromRailroadId = event.from.getAttribute('data-railroad-id')
    let toRailroadId = event.to.getAttribute('data-railroad-id')
    let oldIndex = event.oldIndex
    let newIndex = event.newIndex

    if (fromRailroadId === toRailroadId && newIndex === oldIndex) {
      context.commit('endDragging')
      return // dropped in same position, nothing to commit
    }
    let carList = context.getters.getCarList(event.item.getAttribute('data-car-list-uuid'))

    context.commit('removeDraggedCarList', {
      carList: carList,
      railroadId: fromRailroadId,
    })

    context.commit('addDroppedCarList', {
      carList: carList,
      railroadId: toRailroadId,
      index: newIndex,
    })

    return context.dispatch('pushModifiedRailroads', options)
  },
  pushModifiedRailroads(context, options = {}) {
    let modifiedRailroads = []

    context.state.railroadGroups.forEach(function(railroadGroup) {
      railroadGroup.railroads.forEach(function(railroad) {
        if (railroad.isModified) {
          modifiedRailroads.push(railroad)
        }
      })
    })

    return movementsApi.create(
      context.state.apiToken,
      modifiedRailroads,
      {
        ...options,
        operationId: context.getters.currentOperationId,
        addonableId: context.getters.currentAddonableId,
      }
    )

  },
  updateIsRailRoadGroupCollapsed(context) {
    context.commit('setIsRailRoadGroupCollapsed')
  },
}

export const mutations = {
  ...railroadsMutations,
  ...addonablesMutations,
  ...carListsMutations,
  loadInitialState(state, initialProps) {
    for (var key in initialProps) {
      state[key] = initialProps[key]
    }
  },
  setLoading(state) {
    state.loading = true
  },
  unsetLoading(state) {
    state.loading = false
  },
  showOperationsDropdown(state) {
    state.isOperationsDropdownShown = true
  },
  hideOperationsDropdown(state) {
    state.isOperationsDropdownShown = false
  },
  selectOperation(state, operation) {
    state.currentOperation = operation
    const lastOperationId = operation ? operation.id : null
    setPlantStorage(state.plantId, { lastOperationId })
  },
  unloadOperation(state) {
    state.currentOperation = null
    setPlantStorage(state.plantId, { lastOperationId: null })
  },
  releaseOperation(state, operationId) {
    let filteredOperations = _.reject(state.operations, function(operation){
      return operation.id === operationId
    })

    state.operations = filteredOperations
    state.currentOperation = null
    state.releasingCarList = null
  },
  refreshPlant(state, updatedPlant) {
    // TODO: perhaps we vould hold the whole plant into a single object for easier handling?
    state.plantBracketsCount = updatedPlant.brackets_count
    state.plantBracketsAvailableCount = updatedPlant.brackets_available_count
  },
  refreshRailroads(state, updatedRailroads) {
    (state.railroadGroups || []).forEach(function(railroadGroup) {
      (railroadGroup.railroads || []).forEach(function(railroad, index) {
        (this.updatedRailroads || []).forEach(function(updatedRailroad) {
          if (this.railroad.id === updatedRailroad.id) {
            Vue.set(this.railroadGroup.railroads, this.index, updatedRailroad)
          }
        }, { railroad: railroad, railroadGroup: this.railroadGroup, index: index })
      }, { updatedRailroads: this.updatedRailroads, railroadGroup: railroadGroup })
    }, { updatedRailroads: updatedRailroads })
  },
  refreshplantUpdateToken(state, plantUpdateToken) {
    state.plantUpdateToken = plantUpdateToken
    window.Manovre.plantUpdateToken = plantUpdateToken
  },
  startDragging(state) {
    state.dragging = true
  },
  endDragging(state) {
    state.dragging = false
  },
  removeDraggedCarList(state, params) {
    state.railroadGroups.forEach(function(railroadGroup) {
      railroadGroup.railroads.forEach(function(railroad) {
        if (railroad.id === this.params.railroadId) {
          let updatedCarList = _.without(railroad.car_lists, this.params.carList)
          updatedCarList = _.sortBy(updatedCarList, function(carList){
            return carList.index
          })
          updatedCarList.forEach(function(carList, index) {
            carList.index = index
          })
          railroad.isModified = true
          railroad.car_lists = updatedCarList
        }
      }, { params: this.params, railroadGroup: railroadGroup })
    }, { params: params })
  },
  addDroppedCarList(state, params) {
    state.railroadGroups.forEach(function(railroadGroup) {
      railroadGroup.railroads.forEach(function(railroad) {
        if (railroad.id === this.railroadId) {
          railroad.isModified = true

          // Initialize the new car_list
          let carList = this.carList
          const existingCarsLength = railroad.car_lists.reduce(function(acc, carList) {
            if (carList.cars !== undefined && Array.isArray(carList.cars)) {
              acc += carList.cars.reduce(function(total, car) {
                return total + car.length
              }, 0)
            }
            return acc
          }, 0)

          if (railroad.capacity < carList.cars_length_sum + existingCarsLength) {
            errNotify({ title: state.insufficientRailRoadCapacityWarning })
          }

          // Keep a reference to the previous carList
          carList.parent_id = this.carList.id
          delete carList.id
          carList.uuid = uuidv4()
          carList.index = this.index

          railroad.car_lists.forEach(function(carList) {
            if (carList.index >= this) {
              carList.index = carList.index + 1
            }
          }, this.index)
          railroad.car_lists.push(this.carList)
          railroad.car_lists = _.sortBy(railroad.car_lists, function(carList){
            return carList.index
          })
          railroad.car_lists.forEach(function(carList, index) {
            carList.index = index
          })
        }
      }, this)
    }, params)
  },
  updateDateFromPicker(state, newDate) {
    state.currentDate = newDate ? newDate : ''
  },
  updateOperationType(state, newType) {
    state.currentOperationType = !!newType || newType === 0 ? newType : ''
  },
  setCarSearchResultsFound(state, found) {
    state.carSearchResultsFound = found
  },
  setIsRailRoadGroupCollapsed(state) {
    state.isRailRoadGroupCollapsed = !state.isRailRoadGroupCollapsed
  },
}
