export const railroadBookingInfo = (railroadId, railroadGroupId, currentDate, context) => {
  const pars = {
    railroadId: railroadId,
    currentDate: currentDate,
    railroadGroupId: railroadGroupId,
  }

  callCoreService('bookings/railroadBookingInfo', pars, function(json, textStatus) {
    context.commit('railroadBookingInfo', { info: json })
  },
  { execution_mode: 'web', wait: true })
}
