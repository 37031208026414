<template lang='pug'>
  .railroad.ui.grid(v-bind:class='cssClass')
    .sixteen.wide.mobile.three.wide.tablet.column.three.wide.computer.column
      .ui.grid.railroad__left-block
        .six.wide.mobile.eleven.wide.tablet.eleven.wide.computer.column
          .content-middle.pull-right
            .railroad__factual-railroad-icon(v-if='isFactualRailroad')
              i.icon.train.double(:data-content="factualTooltip")

            .mobile.only.ui.button.tiny.icon.circular.basic(data-popup-trigger)
              i.icon.info.vertical

            .ui.special.popup.railroad__space-saving-popup
              .railroad__name

                <a :href="railroad.car_path" v-if="!readOnlyPlant">
                  strong.railroad__title {{ railroad.name }}
                </a>
                strong.railroad__title(v-else) {{ railroad.name }}
                | &nbsp;
                | &nbsp;
                span.railroad__features
                  span.ui.label.mini.teal(v-for='code in featureCodes') {{ code }}
                br
                p(v-if="!isDisabled") {{ railroad.human_status || '-' }}
                p(style="word-wrap: break-word;" v-if="railroad.note") {{ railroad.note.substr(0, 15) }}
                br
                p(v-if="isDisabled") {{ formatCountdown }}
            .ui.circular.dropdown.simple.item.button.tiny.icon.basic
              i.icon.ellipsis.vertical

              .menu.transition(tabindex='-1')
                .item(v-on:click='showRailroadCarListsMergeModal' v-if='canMergeRailroadCarLists')
                  | {{ $t('merge_all_railroad_car_lists') }}
                .item(v-on:click='toggleActiveRailroad' v-if='canToggleActiveRailroad')
                  | {{ railroadActiveLabel }}
                .item(v-on:click='openTemporaryDisableActiveRailroadModal' v-if='canToggleTemporaryDisableActiveRailroad')
                  | {{ railroadTemporaryDisableActiveLabel }}
                .item(v-on:click='openRailroadNoteModal' v-if='canAddNote')
                  | {{ $t('edit_railroad_note_modal') }}
                .item(v-on:click='openCarRemover' v-if='canRemoveAddCars')
                  | {{ $t('remove_add_cars') }}

          .content-middle.mobile.hidden
            .railroad__name

              <a :href="railroad.car_path" v-if="!readOnlyPlant">
                strong.railroad__title {{ railroad.name }}
              </a>
              strong.railroad__title(v-else) {{ railroad.name }}
              | &nbsp;
              | &nbsp;
              span.railroad__features
                span.ui.label.mini.teal(v-for='code in featureCodes') {{ code }}
              br
              p(v-if="!isDisabled") {{ railroad.human_status || '-' }}
              p(style="word-wrap: break-word;" v-if="railroad.note && minimizeNoteData"  v-on:click='toggleMaximizeNote') {{ railroad.note.substr(0, 15) + ' (...)' }}
              p(v-if="railroad.note && !minimizeNoteData"  v-on:click='toggleMaximizeNote') {{ railroad.note }}
              br
              p(v-if="isDisabled") {{ formatCountdown }}

        .two.wide.mobile.five.wide.tablet.five.wide.computer.column.railroad__insert-from.text-right
          .content-middle
            i.icon.angle.double.right(v-if='canInsertFromLeft')
            i.icon.angle.double.right.icon--disabled(v-else)

            i.icon.angle.double.plug(v-if='isElectrificationAvailableFromLeft')
            i.icon.angle.double.plug.icon--disabled(v-else-if='isPartialElectrificationAvailableFromLeft')
            div(data-popup-trigger v-show="bookedBy")
              i.icon.book.green
            .ui.special.popup
              | {{ bookedBy }}
    .sixteen.wide.mobile.ten.wide.tablet.eleven.wide.computer.column
      .car-lists-wrapper
        .car-lists
          Draggable(
            v-model='draggableCarLists'
            v-on:start='startDragging'
            v-on:end='handleCarListDropped'
            v-bind:forceFallback='true'
            v-bind:data-railroad-id='railroad.id'
            group='carLists'
            fallbackClass='cloned'
            ghostClass='ghost'
            chosenClass='dragging'
            filter='.actions'
            :disabled='!isDraggableEnabled'
          )
            CarList(
              v-for='carList in carLists'
              v-bind:key='carList.uuid'
              v-bind:carList='carList'
              v-bind:railroadId='railroad.id'
              v-bind:railroad='railroad'
              v-bind:searchedCarCode='searchedCarCode'
              v-bind:searchedFullCarCodes='searchedFullCarCodes'
              v-bind:searchedCarTypology='searchedCarTypology'
              v-bind:searchedMaintenanceStatus='searchedMaintenanceStatus'
              v-bind:readOnlyPlant='readOnlyPlant'
            )

    .sixteen.wide.mobile.three.wide.tablet.two.wide.computer.column
      .ui.grid
        .mobile.only.ten.wide.column
          //- mobile-only left-space since .right.floated seems not working
        .three.wide.mobile.six.wide.tablet.five.wide.computer.column.text-right.railroad__insert-from
          .content-middle
            i.icon.angle.double.plug(v-if='isElectrificationAvailableFromRight')
            i.icon.angle.double.plug.icon--disabled(v-else-if='isPartialElectrificationAvailableFromRight')

            i.icon.angle.double.left(v-if='canInsertFromRight')
            i.icon.angle.double.left.icon--disabled(v-else)
            div(data-popup-trigger v-show="bookedBy")
              i.icon.book.green
            .ui.special.popup
              | {{ bookedBy }}
        .two.wide.mobile.ten.wide.tablet.eleven.wide.computer.column.right.aligned.railroad__rail-data
          .mobile.only.ui.button.tiny.icon.circular.basic(data-popup-trigger)
            i.icon.info.vertical

          .mobile.hidden.right.aligned
            RailroadCounter(v-bind:railroad='railroad' v-if='showCounter')

          .ui.special.popup.railroad__space-saving-popup
            .content-middle
              RailroadCounter(v-bind:railroad='railroad' v-if='showCounter')

</template>

<script>
import _ from 'underscore'
import Draggable from 'vuedraggable'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import RailroadCounter from './railroad-counter'
import CarList from './car-list'
import moment from 'moment'

import confirmable from '../mixins/confirmable'

export default {
  components: {
    Draggable,
    RailroadCounter,
    CarList,
  },

  data() {

    return {
      minimizeNoteData: this.minimizeNote,
      countdownInterval: null,
      countdown: null,
      booked_by: null,
    }
  },

  props: {
    railroad: Object,
    searchedCarCode: String,
    searchedFullCarCodes: Array,
    searchedCarTypology: Object,
    searchedMaintenanceStatus: Object,
    minimizeNote: Boolean,
    readOnlyPlant: Boolean,
    currentDate: String,

  },

  mixins: [confirmable],
  mounted: function() {
    moment.locale(this.$i18n.locale)
    this.setupCountdown()

    this.$store.dispatch('railroadBookingInfo', {
      railroadId: this.railroad.id,
      currentDate: this.currentDate,
      railroadGroupId: this.railroad.railroad_group_id,
    })
  },
  computed: {
    ...mapState([
      'currentOperation',
      'currentAddonable',
      'canToggleRailroadActive',
      'permissionToMergeRailroadCarLists',
      'canWriteRailroad',
      'canWriteCarPosition',
      'currentUser',
      'tenant',
      'bookedByResponse',
    ]),
    ...mapGetters([
      'isOperationSelected',
      'useOperationAsCarListName',
    ]),
    formatCountdown() {
      if (this.countdown && this.countdown >= 0) {
        return `${this.$t('reactivation_in')} ${moment.duration(this.countdown, 'seconds').humanize()}`
      }
      return null
    },
    bookedBy() {
      if (this.booked_by && this.booked_by.length > 0) {
        return `${this.$t('booked_by')} ${this.booked_by.map((c) => (c.requesting_company.name + '(' + this.$t('meters', {meters: c.requested_meters}) + ')' )).join(', ')}`
      }
      return null
    },
    factualTooltip() {
      return this.$t('current_factual_tooltip')
    },
    canInsertFromRight() {
      return this.railroad.insert_from !== 'left'
    },
    canInsertFromLeft() {
      return this.railroad.insert_from !== 'right'
    },
    isElectrificationAvailableFromRight() {
      return (
        this.railroad.electrification === 'both' ||
        this.railroad.electrification === 'total_right' ||
        this.railroad.electrification === 'total_right_partial_left'
      )
    },
    isPartialElectrificationAvailableFromRight() {
      return (
        this.railroad.electrification === 'partial_right' ||
        this.railroad.electrification === 'total_left_partial_right' ||
        this.railroad.electrification === 'partial_right_partial_left'
      )
    },
    isElectrificationAvailableFromLeft() {
      return (
        this.railroad.electrification === 'both' ||
        this.railroad.electrification === 'total_left' ||
        this.railroad.electrification === 'total_left_partial_right'
      )
    },
    isPartialElectrificationAvailableFromLeft() {
      return (
        this.railroad.electrification === 'partial_left' ||
        this.railroad.electrification === 'total_right_partial_left' ||
        this.railroad.electrification === 'partial_right_partial_left'
      )
    },
    isDraggableEnabled() {
      if (this.readOnlyPlant) { return false }
      if (!this.currentAddonable) { return true }

      return this.currentAddonable.movement_required &&
        this.currentAddonable.editable
    },
    isModifiedCssClass() {
      return this.railroad.isModified ? ' is-modified' : ''
    },
    carLists() {
      return this.railroad.car_lists
    },
    draggableCarLists: {
      get() {
        return this.carLists
      },
      set(carLists) {
        // no need to set value, already done in carListDropped callback
      },
    },
    isFactualRailroad() {
      if (!this.isOperationSelected) { return }

      return Number(this.currentOperation.factual_railroad_id) === Number(this.railroad.id)
    },
    isDisabled() {
      return !this.railroad.active
    },
    cssClass() {
      let cssClass = this.isDraggableEnabled
        ? 'draggable-railroad'
        : 'undraggable-railroad'

      if (this.isModifiedCssClass) { cssClass += 'is-modified' }
      if (this.isFactualRailroad) { cssClass += ' factual-railroad' }
      if (this.isDisabled) { cssClass += ' disabled' }

      return cssClass
    },
    featureCodes() {
      return (this.railroad.railroad_features || []).map((f) => (f.code))
    },
    canMergeRailroadCarLists() {
      if (this.readOnlyPlant) { return false }
      if (this.isDisabled) { return false }
      if (this.carLists.length < 2) { return false }
      if (!this.permissionToMergeRailroadCarLists) { return false }

      return true
    },
    canAddNote() {
      return this.canWriteRailroad && !this.readOnlyPlant
    },
    canRemoveAddCars() {
      return this.canWriteCarPosition && !this.isDisabled && !this.readOnlyPlant
    },
    canToggleActiveRailroad() {
      return this.canToggleRailroadActive && !this.readOnlyPlant
    },
    canToggleTemporaryDisableActiveRailroad() {
      return this.canToggleRailroadActive && !this.readOnlyPlant
    },
    operationTitle() {
      if (this.isOperationSelected) {
        return this.currentOperation.title
      }

      return null
    },
    railroadActiveLabel() {
      if(this.railroad.active){
        return this.$t('disable_active_railroad')
      } else {
        return this.$t('enable_active_railroad')
      }
    },
    railroadTemporaryDisableActiveLabel() {
      if(this.railroad.active) {
        return this.$t('temporary_disable_active_railroad')
      } else {
        return null
      }
    },
    showCounter() {
      return this.currentUser.internal
    },
  },

  methods: {
    ...mapMutations([
      'startDragging',
    ]),
    ...mapActions([
      'carListDropped',
      'reloadAddonablesForm',
    ]),
    toggleMaximizeNote() {
      if(this.minimizeNoteData)
        this.minimizeNoteData = false
      else
        this.minimizeNoteData = true
    },
    endDragging() {
      this.$store.commit('endDragging')
    },
    updateDroppedCarList(event) {
      const uuid = $(event.item).data('car-list-uuid')
      const carList = this.draggableCarLists.find(cl => (cl.uuid === uuid))
      const code = carList.code || ''
      const title = this.operationTitle
      const hasSameName = title && title == code.substring(0, title.length)
      const message = this.$t('rename_car_list_code_with_operation_title')
      const promise = this.useOperationAsCarListName && !hasSameName
        ? this.confirm({ message })
        : Promise.resolve(false)

      promise.then(useOperationTitle => {
        this.carListDropped({ event, useOperationTitle })
      })
    },
    handleCarListDropped(e) {
      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.updateDroppedCarList(e)
          } else {
            this.endDragging()
          }
        })
    },
    showRailroadCarListsMergeModal(e) {
      e.preventDefault()

      this.$store.dispatch('showRailroadCarListsMergeModal', {
        railroad: this.railroad,
      })
    },
    openTemporaryDisableActiveRailroadModal(e) {
      e.stopPropagation()
      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('showTemporaryDisableActiveRailroadModal', this.railroad, this.toggleActiveRailroad)
          }
        })
    },
    toggleActiveRailroad(e) {
      if (this.railroad.active && this.railroad.car_lists.length > 0) {
        alert(this.$t('cannot_disable_filled_railroads'))
        return
      }

      $.ajax({
        url: this.railroad.toggle_active_path,
        method: 'put',
      }).done((result) => {
        if (result.status === 'ok') {
          const toggledRailroad = {
            ...this.railroad,
            ...{active: !this.railroad.active, disable_active_until: null},
          }

          this.$store.dispatch('refreshRailroads', [toggledRailroad])
          return toggledRailroad
        }

        errNotify({ title: result.error_message })
      })
    },
    openRailroadNoteModal(e) {
      e.stopPropagation()
      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('showRailroadNoteModal', this.railroad)
          }
        })
    },
    openCarRemover(e) {
      e.stopPropagation()
      this
        .confirmMovement()
        .then(confirmed => {
          if (confirmed) {
            this.$store.dispatch('showCarRemoverModal', {carLists: this.carLists, railroad: this.railroad})
          }
        })
    },
    setupCountdown() {
      if (this.railroad.disable_active_until && !this.railroad.active) {
        this.countdownInterval = setInterval(function() {
          // Get today's date and time
          const now = new Date().getTime()
          // Find the distance between now and the count down date
          const distance = new Date(this.railroad.disable_active_until).getTime() - now

          if (distance < 0 || this.railroad.active) {
            const railroad = {
              ...this.railroad,
              ...{active: true, disable_active_until: null},
            }
            this.$store.dispatch('refreshRailroads', [railroad])
            this.countdown = null
            clearInterval(this.countdownInterval)
          }
          this.countdown = distance / 1000
        }.bind(this), 1000)
      } else {
        this.countdown = null
        clearInterval(this.countdownInterval)
      }
    },

  },
  watch: {
    'railroad.disable_active_until' (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.setupCountdown()
      }
    },
    'bookedByResponse' (newVal, oldVal) {
      if (newVal && newVal !== oldVal && newVal.railroadId == this.railroad.id) {
        this.booked_by = newVal.railroadBookingInfo
      }
    },
  },
}
</script>

