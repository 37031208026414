<template lang="pug">
.pairing-table
  vue-good-table(
    :columns="columns"
    :rows="rows"
    :group-options="groupOptions"
    :select-options="selectOptions"
    :pagination-options="paginationOptions"
    styleClass="vgt-table striped bordered condensed"
  )
    template(slot="table-row" slot-scope="props")
      span(v-if="props.column.field == 'checkbox'")
        input(
          type="checkbox",
          :id="props.row['id']",
          :value="props.row['id']",
          v-model="checkedOperation"
          @change="clearOtherOperations(props.row['id'], $event.target.checked)"
        )
      span(v-else-if="props.column.field == 'train'")
        a(:href="'/operations/' + props.row['id']" target='_blank')
          | {{ props.formattedRow[props.column.field] }}
      span(v-else)
        | {{ props.formattedRow[props.column.field] }}
</template>

<script>
import Pagination from '../pagination/Pagination.vue'
import moment from 'moment'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      operationsData: [],
      checkedOperation: [],
      paginationOptions: {
        enabled: false,
        perPage: 5,
      },
      selectOptions: {
        enabled: false,
        selectable: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      groupOptions: {
        enabled: false,
      },
      columns: [
        { field: 'checkbox', width: '15px', sortable: false },
        { field: 'train', label: this.$t('train'), sortable: true, width: '90px' },
        { field: 'from_to', label: '', sortable: true, width: '140px' },
        { field: 'mto', label: this.$t('mto'), sortable: true, width: '140px' },
        { field: 'if', label: this.$t('if'), sortable: true, width: '140px' },
        { field: 'holder', label: this.$t('holder'), sortable: true, width: '140px' },
        { field: 'hour', label: this.$t('hour'), sortable: true, width: '140px' },
        { field: 'date', label: this.$t('date'), sortable: true, dateInputFormat: 'yyyy-MM-dd', width: '90px' },
        { field: 'notes', label: this.$t('notes'), sortable: true },
      ],
    }
  },
  props: {
    operations: Array,
  },

  computed: {
    rows() {
      const result = []
      for (const operation of this.operationsData) {
        result.push({
          train: operation.train,
          from_to: operation.station,
          mto: operation.mto_name,
          if: operation.railway_company_name,
          holder: operation.holder_company_name,
          hour: this.formatProgrammedHour(operation.programmed_hour),
          date: this.formatDoneOn(operation.done_on),
          notes: operation.notes,
          id: operation.id,
        })
      }
      return result
    },
  },
  methods: {
    clearOtherOperations(operationId, value) {
      if (value) {
        this.checkedOperation = [operationId]
      } else {
        this.checkedOperation = []
      }
    },
    formatProgrammedHour(programmedHour) {
      if (programmedHour) return `${moment(programmedHour).format('HH:mm')}`
    },
    formatDoneOn(doneOn) {
      if (doneOn) return `${moment(doneOn).format('DD/MM/YYYY')}`
    },
  },
  mounted() {
    // at this point this.operations is empty
    this.operationsData = [...this.operations]
  },
  watch: {
    operations(newOperations) {
      this.checkedOperation = []
      // operations can be updated from outside
      this.operationsData = [...newOperations]
    },
    checkedOperation(newOperation, oldOperation) {
      this.$emit('toggleOperationToPair', {
        newOperationId: newOperation[0],
        oldOperationId: oldOperation[0],
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.pairing-table
  .max-content
    width: max-content !important

  .table-container-scrollable
    max-height: 79vh
</style>
