<template lang='pug'>
  .car-list-publish-form-fields
    div.showConfirmMessage(v-if='displayConfirmMessage')
      h3.showConfirmMessage__text {{ confirmMessage }}

    form.ui.form(v-bind:class='cssFormClass')
      .fields.three
        .ui.form.field
          label
            | {{ $t('publish_modal.railroad') }}
            | &nbsp;
            span.ui.red.header *
          vSelect(
            class='dropdown-wrapper'
            v-bind:options='railroads'
            v-model='currentRailroad'
            v-bind:value='currentRailroad'
            label="full_name"
            @input="resetInsertFrom"
          )

        .ui.form.field
          label
            | {{ $t('publish_modal.insert_from.label') }}
            | &nbsp;
            span.ui.red.header *
          vSelect(
            class='dropdown-wrapper'
            v-bind:options='insertFromOptions'
            v-model='insertFrom'
            @input="resetAutoselectionThenUpdate"
          )

        .ui.form.field(v-if='showPublishDate')
          label
            | {{ $t('publish_modal.publish_date') }}
            | &nbsp;
            span.ui.red.header *
          date-picker(
            class="wide"
            :lang="datePickerLocale"
            v-model="publishDate"
            format="DD/MM/YYYY HH:mm"
            clearable=false
            type="datetime"
            :time-picker-options="{start: '00:00', step: '00:30', end: '23:30'}"
            @input="handleUpdate"
          )

      div(v-if='settings.mandatory_operation_for_manoeuvres')
        h4 {{ $t('publish_modal.ingress_operation') }}

        .fields.three
          .ui.form.field
            label
              | {{ $t('publish_modal.operations_date') }}
              | &nbsp;
              span.ui.red.header *
            date-picker(
              class='wide'
              :lang="datePickerLocale"
              v-model="operationsDate"
              format="DD/MM/YYYY"
              clearable=false
              type="date"
              @change='resetOperations'
            )

          .ui.form.field
            label {{ $t('publish_modal.source_station') }}
            vSelect(
              class='dropdown-wrapper'
              @search='fetchStationsOptions'
              @search:focus='fetchStationsOptions'
              v-bind:options='stationsOptions'
              v-model='currentSourceStation'
              v-bind:value='currentSourceStation'
              label='name'
              @input="resetOperations"
              :appendToBody="true"
            )

          .ui.form.field
            label
              | {{ $t('publish_modal.operation') }}
              | &nbsp;
              span.ui.red.header *
            vSelect(
              class='dropdown-wrapper'
              @search='fetchOperationsOptions'
              @search:focus='fetchOperationsOptions'
              v-bind:options='operationsOptions'
              label='title'
              v-model='currentOperation'
              v-bind:value='currentOperation'
              @input="checkInsertFromAutoselectionThenUpdate"
            )

        .fields.three
          .ui.form.field
            .ui.toggle.checkbox.spaced
              input(
                type="checkbox"
                id="rename"
                v-on:change='toggleRename'
              )
              label
                | {{ $t('publish_modal.rename') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import moment from 'moment'
import { fetchStations } from '../../api/stations'

export default {
  components: {
    DatePicker,
    vSelect,
  },

  props: {
    railroad: Object,
    railroads: Array,
    formLoading: Boolean,
    carList: Object,
    sourceStationName: String,
    operationTitle: String,
    operationId: Number,
    operationDoneOn: String,
    factualArrivalHour: String,
  },
  watch: {
    railroad: function(newVal, oldVal) {
      this.currentRailroad = newVal
    },
  },

  data() {
    return {
      publishDate: null,
      currentRailroad: null,
      insertFrom: null,
      insertFromAutoselected: false,
      rename: false,
      operationsDate: this.operationDoneOn,
      currentSourceStation: this.sourceStationName,
      internalCurrentOperation: {
        title: this.operationTitle,
        train: this.train,
        id: this.operationId,
      },
      operationsOptions: [],
      showConfirmMessage: false,
      stationsOptions: [],
    }
  },

  computed: {
    ...mapState({
      operations: 'operations',
      apiToken: 'apiToken',
      settings: 'settings',
      tenant: 'tenant',
    }),
    showPublishDate() {
      return this.tenant !== 'sncfr'
    },
    currentOperation: {
      get() {
        return this.internalCurrentOperation
      },
      set(value) {
        if (value && value.typology === 'arrival' && value.factual_arrives_at) {
          const values = value.factual_arrives_at.split('T')[1].split(':')
          this.publishDate.setHours(Number(values[0]))
          this.publishDate.setMinutes((values[1]))
          this.publishDate = new Date(this.publishDate)
        }
        this.internalCurrentOperation = {
          title: value?.title,
          train: value?.train,
          id: value?.id,
        }
      },
    },
    displayConfirmMessage() {
      if (this.formLoading) { return false }

      return this.showConfirmMessage
    },
    confirmationRequired() {
      if (!this.currentOperation) { return false }
      if ((!this.currentOperation.railway_company_id) && (!this.currentOperation.holder_company_id)) { return false }

      let result = false
      this.carList.cars.forEach((car) => {
        if ((car.railway_company_id && car.railway_company_id !=
          this.currentOperation.railway_company_id) || (car.holder_company_id && car.holder_company_id !=
          this.currentOperation.holder_company_id)) {
          result = true
        }
      })

      return result
    },
    submitLabel() {
      return this.$t('publish_modal.submit')
    },
    confirmLabel() {
      return this.$t('publish_modal.confirm')
    },
    confirmMessage() {
      return this.$t('publish_modal.confirm_car_list_publish_message')
    },
    cssFormClass() {
      if (this.formLoading) {
        return 'loading car-list-publish-form'
      }

      if (this.showConfirmMessage) { return 'hide' }

      return 'car-list-publish-form'
    },
    canConfirm() {
      if (this.formLoading) { return false }

      return this.showConfirmMessage
    },
    publishConditions() {
      return this.publishDate && this.currentRailroad && this.insertFrom
    },
    operationConditions() {
      if (!this.settings.mandatory_operation_for_manoeuvres) { return true }

      return !!this.currentOperation
    },
    canPublish() {
      if (this.formLoading) { return false }
      if (this.showConfirmMessage) { return false }

      return (
        this.carList.publish_preconditions
        && this.publishConditions
        && this.operationConditions
      )
    },
    cannotPublish() {
      if (this.formLoading) { return true }
      if (this.showConfirmMessage) { return false }

      return !this.canPublish
    },
    railroadId() {
      return this.currentRailroad && this.currentRailroad.id
    },
    datePickerLocale() {
      return this.$i18n.locale.split('-')[0]
    },
    insertFromOptions() {
      const options = []

      if (!this.currentRailroad) { return options }
      if (this.currentRailroad.insert_from !== 'left') {
        options.push({
          value: 'right',
          label: this.currentRailroad.insert_from_right_label,
        })
      }
      if (this.currentRailroad.insert_from !== 'right') {
        options.push({
          value: 'left',
          label: this.currentRailroad.insert_from_left_label,
        })
      }

      return options
    },
  },

  mounted() {
    this.$nextTick().then(() => {
      if (this.railroad) {
        this.currentRailroad = this.railroad
      }
      if (this.settings.show_factual_time_in_car_list_edit_page) {
        const values = this.factualArrivalHour.split('T')[1].split(':')
        this.publishDate = new Date()
        this.publishDate.setHours(Number(values[0]))
        this.publishDate.setMinutes((values[1]))
        this.publishDate = new Date(this.publishDate)
      } else {
        this.publishDate = new Date()
      }
      this.operationsDate = this.operationsDate ? new Date(this.operationsDate) : new Date()
      this.resetInsertFrom()
      this.fetchStationsOptions()
    })
    // added class to body to style dropdown max-height due to appendToBody prop
    document.body.classList.add('custom-v-select-max-height')
  },

  methods: {
    fetchStationsOptions(search = '', loading = () => {}) {
      loading(true)

      const params = {
        limit: 50,
        q: {
          name_or_dium_code_cont: search,
        },
      }

      fetchStations({ params, apiToken: this.apiToken })
        .then((data) => {
          this.stationsOptions = data
          loading(false)
        })
    },

    fetchOperations(search = '', loading = ()=>{}) {
      const query = { title_or_description_cont: search }

      if (this.operationsDate) {
        const date = moment(this.operationsDate).format('YYYY-MM-DD')
        query.done_on_eq = date
      }

      if (this.currentSourceStation) {
        query.source_station_id_eq = this.currentSourceStation.id
      }

      return $.ajax({
        url: `/car-lists/${this.carList.id}/operations.json`,
        method: 'GET',
        dataType: 'json',
        data: { q: query },
        beforeSend: () => { loading(true) },
        complete: () => { loading(false) },
      })
    },
    fetchOperationsOptions(search = '', loading = ()=>{}) {
      this.fetchOperations(search, loading)
        .done((data) => {
          this.operationsOptions = data.results
        })
    },
    resetInsertFrom() {
      this.insertFrom = null

      if (this.insertFromOptions.length === 1) {
        this.insertFrom = this.insertFromOptions[0]
      }

      this.handleUpdate()
    },
    resetAutoselectionThenUpdate() {
      // once user select insert_from, stop updating automatically
      if (this.settings.automatic_insert_direction_in_carlist_publication) {
        this.insertFromAutoselected = false
      }

      this.handleUpdate()
    },
    checkInsertFromAutoselectionThenUpdate() {
      // autoselect direction as configured for the railroad
      if (
        this.settings.automatic_insert_direction_in_carlist_publication &&
        this.currentRailroad &&
        this.internalCurrentOperation?.train &&
        (!this.insertFrom || this.insertFromAutoselected)
      ) {
        const isTrainEven =
          this.internalCurrentOperation?.train &&
          parseInt(this.internalCurrentOperation?.train) % 2 === 0

        if (isTrainEven) {
          this.currentRailroad?.arrivals_left_default_number_type === 'even' &&
            (this.insertFrom = this.insertFromOptions.find(
              (opt) => opt.value === 'left'
            ))
          this.currentRailroad?.arrivals_right_default_number_type === 'even' &&
            (this.insertFrom = this.insertFromOptions.find(
              (opt) => opt.value === 'right'
            ))
        } else {
          this.currentRailroad?.arrivals_left_default_number_type === 'odd' &&
            (this.insertFrom = this.insertFromOptions.find(
              (opt) => opt.value === 'left'
            ))
          this.currentRailroad?.arrivals_right_default_number_type === 'odd' &&
            (this.insertFrom = this.insertFromOptions.find(
              (opt) => opt.value === 'right'
            ))
        }

        this.insertFromAutoselected = true
      }

      this.handleUpdate()
    },
    resetOperations() {
      this.operationsOptions = []
      this.currentOperation = null
      this.handleUpdate()
    },
    toggleRename() {
      this.rename = !this.rename
      this.handleUpdate()
    },
    handleUpdate() {
      const model = {
        publishDate: this.publishDate,
        currentRailroad: this.currentRailroad,
        insertFrom: this.insertFrom,
        rename: this.rename,
        internalCurrentOperation: this.internalCurrentOperation,
      }

      this.$emit('input', model)
    },
  },
}
</script>

<style lang="sass">

.custom-v-select-max-height
  --vs-dropdown-max-height: 300px

</style>
