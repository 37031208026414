<template lang='pug'>
  modal(name='car-list-edit' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-list-edit(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ $t('edit_modal.update_car_list') }}
        | "{{ carList.name }}"
      .content
        .ui.segment(v-if='showBracketsOperations')
          .ui.dividing.header
            | {{ $t('car_list_clamping') }}
            | &nbsp;
            small {{ carList.name }}
          .text-center(v-if='canPerformBracketsOperations')
            .ui.grid.centered
              .three.wide.column
                .ui.toggle.checkbox.spaced
                  input(type='checkbox' v-model='clamps' v-on:change='updateBrackets')
                  label {{ $t('edit_modal.clamps') }}
              .six.wide.column
                vSelect(
                  :disabled='!clamps'
                  @search='fetchBracketsOptions'
                  @search:focus='fetchBracketsOptions'
                  :options='leftBracketsOptions'
                  v-model='leftBracket'
                  v-bind:value='leftBracket'
                  v-on:input='updateBrackets'
                )

              .six.wide.column
                vSelect(
                  :disabled='!clamps'
                  @search='fetchBracketsOptions'
                  @search:focus='fetchBracketsOptions'
                  :options='rightBracketsOptions'
                  v-model='rightBracket'
                  v-bind:value='rightBracket'
                  v-on:input='updateBrackets'
                )
              .three.wide.column

              .six.wide.column
                label {{ $t('edit_modal.left_car_position') }}
                vSelect(
                  :disabled='!clamps || !this.leftBracketCode'
                  :options='carOptions'
                  :reduce="car => car.code"
                  v-model='leftCarPosition'
                  v-bind:value='leftCarPosition'
                  v-on:input='updateBrackets'
                )
              .six.wide.column
                label {{ $t('edit_modal.right_car_position') }}
                vSelect(
                  :disabled='!clamps || !this.rightBracketCode'
                  :options='carOptions'
                  :reduce="car => car.code"
                  v-model='rightCarPosition'
                  v-bind:value='rightCarPosition'
                  v-on:input='updateBrackets'
                )
          LocomotiveBracketsForm(
            v-for='locomotive in locomotives'
            v-bind:key='locomotive.id'
            v-bind:locomotive='locomotive'
            v-bind:carList='carList'
            v-if='canPerformBracketsOperations'
            @updated='handleLocomotiveBracketsChange'
            @changed='handleLocomotiveBracketsModified'
          )

        .merge-actions.ui.form(v-if='canMerge')
          h4 {{ $t('edit_modal.merge_car_list') }}

          p.actions
            .ui.button.positive(v-if='canMergeLeft' v-on:click='toggleMergeLeft')
              i.angle.double.left.icon
              | {{ $t('edit_modal.merge_on_left') }}

            .ui.button.positive(v-if='canMergeRight' v-on:click='toggleMergeRight')
              | {{ $t('edit_modal.merge_on_right') }}
              i.angle.double.right.icon

          .merge-dialog.ui.segment(v-bind:class='mergeLeftDialogCssClass')
            p.text-center
              | {{ $t('edit_modal.merge_left_carl_list') }}
              | &nbsp;
              strong {{ prevCarListName }}

            .car-list-wrapper
              Car(v-for='car in prevCarListCars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList.prevCarList')

            CarListsMergeFields(
              v-bind:carList='carList'
              v-model='mergeFields'
            )

            .ui.button.blue(v-if='canMergeLeft' v-on:click='mergeLeft')
              | {{ $t('edit_modal.merge') }}

          .merge-dialog.ui.segment(v-bind:class='mergeRightDialogCssClass')
            p.text-center
              | {{ $t('edit_modal.merge_right_carl_list') }}
              | &nbsp;
              strong {{ nextCarListName }}

            .car-list-wrapper
              Car(v-for='car in nextCarListCars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList.nextCarList')

            CarListsMergeFields(
              v-bind:carList='carList'
              v-model='mergeFields'
            )

            .ui.button.blue(v-if='canMergeRight' v-on:click='mergeRight')
              | {{ $t('edit_modal.merge') }}

        .split-header(v-if='canSplit')
          h4
            | {{ $t('edit_modal.divide_list') }}
            | {{ carsLengthTitle }}
          .text-center(v-if='hasMoreThanOneCar')
            .ui.toggle.checkbox
              input(type='checkbox' v-model='splitAgain')
              label {{ $t('edit_modal.divide_again') }}

        .split-header.ui.grid(v-else)
          .row.padded
            .one.wide.column &nbsp;
            .fourteen.wide.column
              .ui.warning.ignored.message.text-center
                h4 {{ $t('edit_modal.clamped_car_list_cannot_changed') }}

        .car-list-split-preview-wrapper.ui.grid.form(v-if='splitting')
          .row.padded
            .eight.wide.column
              .field.string
                input.car-list-edit__code-input(type='text' v-model='codeA' :placeholder='newCarListNamePlaceholder')
              .field.text
                textarea(type='text' v-model='noteA' :placeholder='newCarListNotePlaceholder' style='height: 6px')

              .car-list-wrapper.car-list-split-preview
                Car(v-for='car in leftCars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList')

              br

              .car-list-split-counter
                | {{ splitIndex }}
                span.small
                  | {{ leftLength }}

            .eight.wide.column
              .field.string
                input.car-list-edit__code-input(type='text' v-model='codeB' :placeholder='newCarListNamePlaceholder')
              .field.text
                textarea(type='text' v-model='noteB' :placeholder='newCarListNotePlaceholder' style="height: 6px")

              .car-list-wrapper.car-list-split-preview
                Car(v-for='car in rightCars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList')

              br

              .car-list-split-counter
                | {{ rightCounter }}
                span.small
                  | {{ rightLength }}

        .car-list-split-preview-wrapper.ui.grid.form(v-else)
          .row.padded
            .sixteen.wide.column
              .car-list-wrapper.car-list-split-preview
                .clamp(v-if='clamps')
                Car(v-for='car in carList.cars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList')
                .clamp(v-if='clamps')

        vue-slider(v-if='hasMoreThanOneCar' v-model='splitIndex' v-bind:min=0 v-bind:max='sliderMax'
          :marks='sliderMarksOptions' v-bind:adsorb='true' v-bind:hideLabel='true'
          :tooltip-formatter='carTooltip' :tooltip="'focus'")

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('close') }}
        .ui.button.blue(v-if='canInvert' v-on:click='invert')
          | {{ $t('edit_modal.invert_list') }}
        .ui.button.positive(v-if='splitting' v-on:click='split')
          | {{ $t('edit_modal.divide_list') }}
        .ui.button.yellow(v-if='showSplitAllAction' v-on:click='splitAll')
          | {{ $t('edit_modal.divide_all_cars') }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import 'vue-slider-component/theme/antd.css'
import Car from '../car'
import CarListsMergeFields from './car-list-merge-fields'
import LocomotiveBracketsForm from '../LocomotiveBracketsForm'

export default {
  components: {
    Car,
    VueSlider,
    vSelect,
    CarListsMergeFields,
    LocomotiveBracketsForm,
  },

  data() {
    return {
      splitIndex: 0,
      splitAgain: false,
      clamps: false,
      bracketsOptions: [],
      leftBracket: null,
      rightBracket: null,
      leftCarPosition: null,
      rightCarPosition: null,
      codeA: '',
      codeB: '',
      noteA: '',
      noteB: '',
      mergeDialog: null, // Values: null | left | right
      mergeFields: {
        code: null,
        train: null,
      },
      locomotivesClampedState: {},
      locoCorrectClamps: {},
    }
  },

  computed: {
    ...mapState({
      carList: 'editingCarList',
      operations: 'operations',
      currentOperation: 'currentOperation',
      currentAddonable: 'currentAddonable',
      tenant: 'tenant',
    }),
    ...mapGetters([
      'manipulationDisabled',
      'carListUnavailable',
      'useOperationAsCarListName',
      'isOperationSelected',
      'carLists',
    ]),
    locomotives() {
      return (this.carList.cars || []).filter(
        (car) => (car.resource_type === 'locomotive')
      )
    },
    carOptions() {
      var carOptions = []
      this.carList.cars.forEach((car) => {
        carOptions.push({ label: this.carId(car), code: car.id })
      })
      return carOptions
    },
    operationTitle() {
      if (this.isOperationSelected) {
        return this.currentOperation.title
      }

      return null
    },
    mergeCode() {
      return this.mergeFields.code
    },
    mergeTrain() {
      return this.mergeFields.train
    },
    mergeTrainId() {
      return this.mergeTrain ? this.mergeTrain.id : undefined
    },
    leftBracketCode() {
      return this.leftBracket ? this.leftBracket.code : null
    },
    rightBracketCode() {
      return this.rightBracket ? this.rightBracket.code : null
    },
    leftBracketsOptions() {
      if (!this.rightBracket) {
        return this.bracketsOptions
      }

      return this.bracketsOptions.filter((b) => (b.code !== this.rightBracketCode))
    },
    rightBracketsOptions() {
      if (!this.leftBracket) {
        return this.bracketsOptions
      }

      return this.bracketsOptions.filter((b) => (b.code !== this.leftBracketCode))
    },
    canPerformBracketsOperations() {
      if (!this.showBracketsOperations) { return false }
      if (!this.currentAddonable) { return true }

      return this.currentAddonable.allow_brackets_operations
    },
    showBracketsOperations(){
      return this.tenant != 'sncfr'
    },
    newCarListNamePlaceholder() {
      return this.$t('edit_modal.new_car_list_name_placeholder')
    },
    newCarListNotePlaceholder() {
      return this.$t('edit_modal.new_car_list_note_placeholder')
    },
    prevCarListName() {
      if (!this.carList) { return }

      return (this.carList.prevCarList || {}).name
    },
    nextCarListName() {
      if (!this.carList) { return }

      return (this.carList.nextCarList || {}).name
    },
    prevCarListCars() {
      if (!this.carList) { return }

      return (this.carList.prevCarList || {}).cars || []
    },
    nextCarListCars() {
      if (!this.carList) { return }

      return (this.carList.nextCarList || {}).cars || []
    },
    mergeLeftDialogCssClass() {
      if (this.mergeDialog === 'left') { return '' }

      return 'hide'
    },
    mergeRightDialogCssClass() {
      if (this.mergeDialog === 'right') { return '' }

      return 'hide'
    },
    canManipulate() {
      return !this.manipulationDisabled
    },
    isCarListClamped() {
      return !!(this.leftBracket && this.rightBracket)
    },
    areLocomotivesClamped() {
      const clampsMap = Object.values(this.locomotivesClampedState)
      return clampsMap.find((v) => (v === true))
    },
    isClamped() {
      return this.isCarListClamped || this.areLocomotivesClamped
    },
    canSplit() {
      return !this.isClamped
    },
    canMerge() {
      return (this.canMergeLeft || this.canMergeRight)
    },
    canMergeLeft() {
      return (
        !this.isClamped
        && this.carList.prevCarListUuid !== undefined
        && this.carList.prevCarList.clamps !== true
        && !this.carListUnavailable(this.carList.prevCarList)
      )
    },
    canMergeRight() {
      return (
        !this.isClamped
        && this.carList.nextCarListUuid !== undefined
        && this.carList.nextCarList.clamps !== true
        && !this.carListUnavailable(this.carList.nextCarList)
      )
    },
    carsLengthTitle() {
      return '(' + this.calculareCarsLength(this.carList.cars) + 'm)'
    },
    carsCount() {
      return this.carList.cars.length
    },
    // NOTE: the slider stars from 0
    sliderMax() {
      return this.carsCount - 1
    },
    hasMoreThanOneCar() {
      if (this.isClamped) { return false }

      return this.carsCount > 1
    },
    leftLength() {
      return '(' + this.calculareCarsLength(this.carList.cars.slice(0, this.splitIndex)) + 'm)'
    },
    leftCars() {
      let leftCars = []
      this.carList.cars.forEach(function(car, index) {
        if (index < this) {
          leftCars.push(car)
        }
      }, this.splitIndex)
      return leftCars
    },
    rightCounter() {
      return this.carList.cars.length - this.splitIndex
    },
    rightLength() {
      return '(' + this.calculareCarsLength(this.carList.cars.slice(this.splitIndex)) + 'm)'
    },
    rightCars() {
      let rightCars = []
      this.carList.cars.forEach(function(car, index) {
        if (index >= this) {
          rightCars.push(car)
        }
      }, this.splitIndex)
      return rightCars
    },
    splitting() {
      if (this.isClamped) { return false }

      return this.splitIndex > 0 && this.splitIndex < this.carsCount
    },
    showSplitAllAction() {
      if (this.isClamped) { return false }

      return !this.splitting && this.carList.cars.length > 1
    },
    hasCars() {
      return this.carList.cars.length > 1
    },
    canInvert() {
      return this.hasCars && this.canManipulate
    },
    railroadId() {
      return this.carList.railroad_id
    },
    nextAvailableCarListOperationNameCounter() {
      if (!this.useOperationAsCarListName) { return 1 }

      const title = this.operationTitle
      const extractCounter = code => (
        parseInt(_.last(code.replace(title, '').split('-')) || 0, 10)
      )
      const clCounters = this.carLists.map((cl) => {
        const code = cl.code || ''
        const sub = code.substring(0, title.length)
        const hasSameName = title && title === sub

        return hasSameName
          ? extractCounter(code)
          : 0
      })

      return (_.max(clCounters) || 0) + 1
    },
  },

  beforeMount() {
    this.locomotives.forEach(({ resource_id, left_bracket, right_bracket }) => {
      this.locomotivesClampedState[resource_id] = !!(left_bracket && right_bracket)
    })
  },

  mounted() {
    const carList = this.carList

    this.$modal.show('car-list-edit')
    this.clamps = carList.clamps
    this.leftBracket = carList.left_bracket
    this.rightBracket = carList.right_bracket
    this.leftCarPosition = carList.left_car_position_id
    this.rightCarPosition = carList.right_car_position_id

    this.locoCorrectClamps = this.locomotives.reduce((acc, loco) => {acc[loco.id] = true; return acc}, {})

    // Set the splitted code names
    const uuid = carList.uuid
    const code = carList.code || ''
    const title = this.operationTitle
    const hasSameName = title && title == code.substring(0, title.length)

    this.noteA = carList.notes
    this.noteB = carList.notes

    if (this.useOperationAsCarListName && !hasSameName) {
      const counter = this.nextAvailableCarListOperationNameCounter

      this.codeA = `${title} - ${counter}`
      this.codeB = `${title} - ${counter + 1}`
      return
    }

    if (code.length > 0) {
      this.codeA = `${code} - 1`
      this.codeB = `${code} - 2`
    }
  },

  methods: {
    carId(car) {
      const carInfo = [
        this.tenant == 'sncfr' && car.resource_type == 'car' ? car.code : null,
        this.tenant != 'sncfr' && car.code ? car.code.slice(-4) : null,
        this.tenant == 'sncfr' && car.resource_type == 'locomotive' ? car.serial : null,
      ].filter(Boolean)

      return carInfo.join('\n')
    },
    setLocomotivesClampedState(locomotive, value) {
      const { resource_id } = locomotive

      this.locomotivesClampedState = {
        ...this.locomotivesClampedState,
        [resource_id]: !!value,
      }
    },
    handleLocomotiveBracketsChange({ locomotive, isClamped }) {
      this.setLocomotivesClampedState(locomotive, isClamped)
    },
    handleLocomotiveBracketsModified({ locomotive, saneState }){
      this.locoCorrectClamps[locomotive.id] = saneState
    },
    carTooltip(index) {
      return this.carList.cars[index].code
    },
    sliderMarksOptions(index) {
      return {
        label: `Label ${index}`,
      }
    },
    closeModal() {
      let canClose = true

      Object.keys(this.locoCorrectClamps).forEach(key => {
        if (!this.locoCorrectClamps[key]) {
          canClose = false
        }
      })

      if (!canClose || this.clamps && (!this.leftBracket || !this.rightBracket)) {
        canClose = confirm(this.$t('confirm_incomplete_clamps'))
      }

      if (canClose) {
        this.$modal.hide('car-list-edit')
      }
    },
    onClosed() {
      this.$emit('closed')
      this.$store.commit('editingCarList', null)
    },
    calculareCarsLength(cars) {
      var carsLength = 0
      cars.forEach(function(car) {
        carsLength += car.length
      }, carsLength)

      return +(carsLength).toFixed(2)
    },
    updateBrackets() {
      if (this.leftBracketCode === this.rightBracketCode) {
        this.rightBracket = null
      }

      // We need both brackets before update them
      if (this.clamps && (!this.leftBracket || !this.rightBracket)) {
        return
      }
      this.$store.dispatch('carListClamps', {
        carListUuid: this.carList.uuid,
        clampsValue: this.clamps,
        leftBracketId: (this.clamps ? this.leftBracketCode : null),
        rightBracketId: (this.clamps ? this.rightBracketCode : null),
        leftCarPositionId: (this.clamps ? this.leftCarPosition : null),
        rightCarPositionId: (this.clamps ? this.rightCarPosition : null),
      }).then(() => {
        this.$store.dispatch('reloadAddonablesForm')

        if (!this.clamps) {
          this.leftBracket = null
          this.rightBracket = null
        }
      })
    },
    toggleMergeLeft() {
      this.mergeDialog = this.mergeDialog === 'left'
        ? null
        : 'left'
    },
    toggleMergeRight() {
      this.mergeDialog = this.mergeDialog === 'right'
        ? null
        : 'right'
    },
    mergeLeft() {
      this.$store.dispatch('carListsMerge', {
        leftCarListUuid: this.carList.prevCarListUuid,
        rightCarListUuid: this.carList.uuid,
        carListCode: this.mergeCode,
        trainId: this.mergeTrainId,
        beforeDeselection: () => { this.$emit('closed') },
      })
    },
    mergeRight() {
      this.$store.dispatch('carListsMerge', {
        leftCarListUuid: this.carList.uuid,
        rightCarListUuid: this.carList.nextCarListUuid,
        carListCode: this.mergeCode,
        trainId: this.mergeTrainId,
        beforeDeselection: () => { this.$emit('closed') },
      })
    },
    split() {
      this.$store.dispatch('carListSplit', {
        carListUuid: this.carList.uuid,
        splitIndex: this.splitIndex,
        splitAgain: this.splitAgain,
        codes: [this.codeA, this.codeB],
        notes: [this.noteA, this.noteB],
        beforeDeselection: () => { this.$emit('closed') },
      }).then(() => { this.$store.dispatch('reloadAddonablesForm') })
    },
    splitAll() {
      this.$store
        .dispatch('carListSplitAll', {
          carListUuid: this.carList.uuid,
          beforeDeselection: () => { this.$emit('closed') },
        }).then(() => { this.$store.dispatch('reloadAddonablesForm') })
    },
    fetchBrackets(search = '', loading = () => {}) {
      const plantId = this.$store.state.plantId
      const data = {
        q: { name_or_code_cont: search },
        railroad_group_id: this.carList.railroad_group_id,
        target: 'CarList',
      }

      return $.ajax({
        url: `/admin/plants/${plantId}/brackets`,
        method: 'GET',
        dataType: 'json',
        data,
        beforeSend: () => { loading(true) },
        complete: () => { loading(false) },
      })
    },
    fetchBracketsOptions(search = '', loading = ()=>{}) {
      this.setBracketsOptions([])

      return this
        .fetchBrackets(search, loading)
        .done((data) => {
          this.setBracketsOptions(data.brackets)
        })
    },
    setBracketsOptions(brackets) {
      this.bracketsOptions = brackets
      // this.leftBracketsOptions = brackets;
      // this.rightBracketsOptions = brackets;
    },
    invert() {
      this.$store.dispatch('carListInvert', {
        carListUuid: this.carList.uuid,
        railroadId: this.railroadId,
      }).then((result) => {
        if (!result) { return }

        const railroad = result.railroads[0]

        railroad.car_lists.some(cl => {
          if (cl.uuid === this.carList.uuid) {
            this.$store.commit('editingCarList', cl)
            return true
          }
        })
      })
    },
  },
}
</script>
