<template lang="pug">
.pairing-table
  vue-good-table(
    @on-selected-rows-change="selectionChanged"
    :columns="columns"
    :rows="rows"
    :group-options="groupOptions"
    :select-options="selectOptions"
    :search-options="searchOptions"
    styleClass="vgt-table striped bordered condensed"
  )
    template(slot="table-row" slot-scope="props")
      span(v-if="props.column.field == 'train'")
        a(:href="'/operations/' + props.row['id']" target='_blank')
          | {{ props.row['train1'] }}
        | {{ '-' }}
        a(:href="'/operations/' + props.row['id2']" target='_blank')
          | {{ props.row['train2'] }}

      span(v-else)
        | {{ props.formattedRow[props.column.field] }}

</template>

<script>
import moment from 'moment'

export default {
  components: {
  },
  data() {
    return {
      operationsData: [],
      checkedOperations: [],

      searchOptions: {
        enabled: false,
      },
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      groupOptions: {
        enabled: false,
        collapsable: false,
      },
      columns: [
        { field: 'train', label: this.$t('train'), sortable: true },
        { field: 'from_to', label: this.$t('from_to'), sortable: true },
        { field: 'mto', label: this.$t('mto'), sortable: true },
        { field: 'if', label: this.$t('if'), sortable: true },
        { field: 'holder', label: this.$t('holder'), sortable: true },
        { field: 'hour', label: this.$t('hour'), sortable: true },
        { field: 'date', label: this.$t('date'), sortable: true, dateInputFormat: 'yyyy-MM-dd' },
        { field: 'notes', label: this.$t('notes'), sortable: true },
      ],
    }
  },
  props: {
    operations: Array,
  },
  computed: {
    rows() {
      const result = []
      for (const operation of this.operationsData) {
        result.push({
          train1: operation[0]?.train,
          train2: operation[1]?.train,
          from_to: operation[0]?.station + ' - ' + operation[1]?.station,
          mto: operation[0]?.mto_name + ' - ' + operation[1]?.mto_name,
          if: operation[0]?.railway_company_name + ' - ' + operation[1]?.railway_company_name,
          holder: operation[0]?.holder_company_name + ' - ' + operation[1]?.holder_company_name,
          hour: this.formatProgrammedHour(operation[0]?.programmed_hour) + ' - ' + this.formatProgrammedHour(operation[1]?.programmed_hour),
          date: this.formatDoneOn(operation[0]?.done_on) + ' - ' + this.formatDoneOn(operation[1]?.done_on),
          notes: operation[0]?.notes + ' - ' + operation[1]?.notes,
          id: operation[0]?.id,
          id2: operation[1]?.id,
        })
      }
      return result
    },
  },
  methods: {
    selectionChanged(params) {
      this.checkedOperations = params.selectedRows.map(x => x.id)
    },
    formatProgrammedHour(programmed_hour) {
      if (programmed_hour) return `${moment(programmed_hour).format('HH:mm')}`
    },
    formatDoneOn(doneOn) {
      if (doneOn) return `${moment(doneOn).format('DD/MM/YYYY')}`
    },
    organizeOperations(operations) {
      var organizedOperationIds = []
      var organizedOperations = []
      operations.forEach((operation) => {
        if (!organizedOperationIds.includes(operation.id) && !organizedOperationIds.includes(operation.paired_operation_id)) {
          var paired_operation = operations.filter(op => op.id === operation.paired_operation_id)[0]
          if (operation.typology === 'arrival') {
            organizedOperations.push([operation, paired_operation])
          } else {
            organizedOperations.push([paired_operation, operation])
          }
          organizedOperationIds.push(operation.id)
          organizedOperationIds.push(operation.paired_operation_id)
        }
      })
      return organizedOperations
    },
  },
  mounted() {
    // at this point this.operations is empty
    this.operationsData = this.organizeOperations(this.operations)
  },
  watch: {
    operations(newOperations) {
      this.checkedOperations = []
      // operations can be updated from outside
      this.operationsData = this.organizeOperations(newOperations)
    },
    checkedOperations(newOperations) {
      this.$emit('saveOperationsToUnpair', newOperations)
    },
  },
}
</script>

<style lang="sass" scoped>
.pairing-table
  .max-content
    width: max-content !important

  .table-container-scrollable
    max-height: 79vh
</style>
