import dayjs from 'dayjs'

import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

dayjs.extend(advancedFormat)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)

export default {
  install: function (Vue, options) {
    Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs })
  },
}
