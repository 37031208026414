<template lang='pug'>
  modal(name='car-list-split-at-modal' class='vue-modal' width='900px' height='auto' v-bind:adaptive='true' v-on:closed='onClosed')
    .ui.modal.visible.active.car-list-edit.active.car-list-split-at-modal(style="overflow: auto; max-height: 100vh;")
      div(slot='top-right')
        i.close.icon(v-on:click='closeModal')
      .header
        | {{ $t('edit_modal.update_car_list') }}
        | "{{ carList.name }}"

      .content
        .split-header
          h4
            | {{ $t('edit_modal.divide_list') }}
            | {{ carsLengthTitle }}

        .car-list-split-preview-wrapper.ui.grid.form(v-if='splitting')
          .row.padded
            .six.wide.column
              .field.string
                input.car-list-edit__code-input(type='text' v-model='codeA' :placeholder='newCarListNamePlaceholder')
              .field.text
                textarea(type='text' v-model='noteA' :placeholder='newCarListNotePlaceholder' style='height: 6px')

              .car-list-wrapper.car-list-split-preview
                Car(v-for='car in leftCars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList')

              br

              .car-list-split-counter
                | {{ splitIndex }}
                | &nbsp;
                span.small
                  | {{ leftLength }}

            .four.wide.column
              .field.string
                input.car-list-edit__code-input(type='text' v-model='codeB' :placeholder='newCarListNamePlaceholder')
              .field.text
                textarea(type='text' v-model='noteB' :placeholder='newCarListNotePlaceholder' style='height: 6px')

              .car-list-wrapper.car-list-split-preview
                Car(v-bind:car='currentCar' v-bind:carList='carList')

              br

              .car-list-split-counter
                span.small
                  | {{ currentCarLength }}

            .six.wide.column
              .field.string
                input.car-list-edit__code-input(type='text' v-model='codeC' :placeholder='newCarListNamePlaceholder')
              .field.text
                textarea(type='text' v-model='noteC' :placeholder='newCarListNotePlaceholder' style='height: 6px')

              .car-list-wrapper.car-list-split-preview
                Car(v-for='car in rightCars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList')

              br

              .car-list-split-counter
                | {{ rightCounter }}
                | &nbsp;
                span.small
                  | {{ rightLength }}

        .car-list-split-preview-wrapper.ui.grid.form(v-else)
          .row.padded
            .sixteen.wide.column
              .car-list-wrapper.car-list-split-preview
                Car(v-for='car in carList.cars' v-bind:key='car.id' v-bind:car='car' v-bind:carList='carList')

        vue-slider(v-if='hasMoreThanOneCar' v-model='splitIndex' v-bind:min=0 v-bind:max='sliderMax'
          :marks='sliderMarksOptions' v-bind:adsorb='true' v-bind:hideLabel='true'
          :tooltip-formatter='carTooltip' :tooltip="'focus'")

      .actions
        .ui.cancel.button(v-on:click='closeModal')
          | {{ $t('close') }}
        .ui.button.positive(v-if='splitting' v-on:click='split')
          | {{ $t('edit_modal.divide_list') }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import 'vue-slider-component/theme/antd.css'
import Car from '../car'

export default {
  components: {
    Car,
    VueSlider,
    vSelect,
  },

  data() {
    return {
      splitIndex: 0,
      codeA: '',
      codeB: '',
      codeC: '',
      noteA: '',
      noteB: '',
      noteC: '',
    }
  },

  computed: {
    ...mapState({
      carList: 'splittingCarList',
      operations: 'operations',
      currentOperation: 'currentOperation',
      currentAddonable: 'currentAddonable',
      tenant: 'tenant',
    }),
    ...mapGetters([
      'manipulationDisabled',
      'carListUnavailable',
      'useOperationAsCarListName',
      'maintainCarListNameWhenSplitting',
      'isOperationSelected',
      'carLists',
    ]),
    operationTitle() {
      if (this.isOperationSelected) {
        return this.currentOperation.title
      }

      return null
    },
    isClamped() {
      return this.isCarListClamped || this.areLocomotivesClamped
    },
    canSplit() {
      return !this.isClamped
    },
    cars() {
      return this.carList.cars
    },
    carsLengthTitle() {
      return '(' + this.calculareCarsLength(this.carList.cars) + 'm)'
    },
    carsCount() {
      return this.cars.length
    },
    // NOTE: the slider starts from 0 and we must keep 1 car on the right side
    sliderMax() {
      return this.cars.length - 2
    },
    hasMoreThanOneCar() {
      if (this.isClamped) { return false }

      return this.carsCount > 1
    },
    leftLength() {
      return '(' + this.calculareCarsLength(this.carList.cars.slice(0, this.splitIndex)) + 'm)'
    },
    leftCars() {
      let leftCars = []
      this.carList.cars.forEach(function(car, index) {
        if (index < this) {
          leftCars.push(car)
        }
      }, this.splitIndex)
      return leftCars
    },
    currentCar() {
      return this.cars[this.splitIndex]
    },
    currentCarLength() {
      return `(${this.calculareCarsLength([this.currentCar])}m)`
    },
    rightCounter() {
      return this.carList.cars.length - this.splitIndex - 1
    },
    rightLength() {
      return '(' + this.calculareCarsLength(this.carList.cars.slice(this.splitIndex + 1)) + 'm)'
    },
    rightCars() {
      let rightCars = []
      this.carList.cars.forEach(function(car, index) {
        if (index > this) {
          rightCars.push(car)
        }
      }, this.splitIndex)
      return rightCars
    },
    splitting() {
      return this.splitIndex > 0 && this.splitIndex < this.carsCount
    },
    nextAvailableCarListOperationNameCounter() {
      if (!this.useOperationAsCarListName) { return 1 }

      const title = this.operationTitle
      const extractCounter = code => (
        parseInt(_.last(code.replace(title, '').split('-')) || 0, 10)
      )
      const clCounters = this.carLists.map((cl) => {
        const code = cl.code || ''
        const sub = code.substring(0, title.length)
        const hasSameName = title && title === sub

        return hasSameName
          ? extractCounter(code)
          : 0
      })

      return (_.max(clCounters) || 0) + 1
    },
    newCarListNamePlaceholder() {
      return this.$t('edit_modal.new_car_list_name_placeholder')
    },
    newCarListNotePlaceholder() {
      return this.$t('edit_modal.new_car_list_note_placeholder')
    },
  },

  mounted() {
    this.$modal.show('car-list-split-at-modal')

    // Set the splitted code names
    const carList = this.carList
    const code = carList.code || ''
    const title = this.operationTitle
    const hasSameName = title && title == code.substring(0, title.length)

    // names are based on Operation
    if (this.useOperationAsCarListName && !hasSameName) {
      const counter = this.nextAvailableCarListOperationNameCounter

      this.codeA = `${title} - ${counter}`
      this.codeB = `${title} - ${counter + 1}`
      this.codeC = `${title} - ${counter + 2}`
      return
    }

    // names are based on parent CarList
    if (code.length > 0) {
      this.codeA = `${code} - 1`
      this.codeB = this.maintainCarListNameWhenSplitting ? `${code} - 2` : this.currentCar.formatted_code
      this.codeC = `${code} - 3`
    }
    this.noteA = carList.notes
    this.noteB = carList.notes
    this.noteC = carList.notes
  },

  methods: {
    carTooltip(index) {
      return this.carList.cars[index].code
    },
    sliderMarksOptions(index) {
      return {
        label: `Label ${index}`,
      }
    },
    closeModal() {
      this.$modal.hide('car-list-split-at-modal')
    },
    onClosed() {
      this.$emit('closed')
      this.$store.commit('editingCarList', null)
    },
    calculareCarsLength(cars) {
      var carsLength = 0
      cars.forEach(function(car) {
        carsLength += car.length
      }, carsLength)

      return +(carsLength).toFixed(2)
    },
    split() {
      this.$store.dispatch('carListPopAt', {
        carListUuid: this.carList.uuid,
        splitIndex: this.splitIndex,
        codes: [this.codeA, this.codeB, this.codeC],
        notes: [this.noteA, this.noteB, this.noteC],
        beforeDeselection: () => { this.$emit('closed') },
      }).then(() => { this.$store.dispatch('reloadAddonablesForm') })
    },
  },
}
</script>
